import React, { FunctionComponent } from "react";
import Icon from "./../Icon";
import CheckBox from "./../CheckBox";
import DropDownList from "./../DropDownList";
import { generateId } from "../_util/common.js";
import ThemeProvider from "./../ThemeProvider";
import styled, { css } from "styled-components";

type Column = {
  title: string;
  dataIndex: string;
  key: number;
  sortable?: boolean;
  width?: string;
  displayValue?: any;
};

type Row = {
  key: number;
};

type Filter = {
  title: string;
  value: string;
  logic: (record: Row) => Row;
};

enum Theme {
  default = "default",
  wsol = "wsol",
}

enum HeaderType {
  default = "default",
  basic = "basic",
}

type Props = {
  columns: Column[];
  sortingData?: {
    dataIndex: string;
    direction: string;
    onSort: (dataIndex: string) => void;
  };
  selectionData?: {
    selectedAll: boolean;
    mixedState: boolean;
    onSelect: (status: string) => void;
    filter?: Filter[];
    selectedFilter?: string;
    hideSelectLabel?: boolean;
    columnWidth?: string;
    headerType?: HeaderType;
  };
  theme: Theme;
  uuid: string;
  designSystem?: string;
};

const TableHeader: FunctionComponent<Props> = (props) => {
  
  function renderSortableCell(column: Column, context: any) {
    let sort_type:string = "Unsorted";
    if(props.sortingData.dataIndex === column.dataIndex && props.sortingData.direction === "asc"){
      sort_type = "Sorted descending";
    }else if(props.sortingData.dataIndex === column.dataIndex && props.sortingData.direction !== "asc"){
      sort_type = "Sorted ascending";
    }
    return (
      <SortableCellButton
        context={context}
        theme={props.theme}
        isActive={props.sortingData.dataIndex === column.dataIndex}
        onClick={() => {
          props.sortingData.onSort(column.dataIndex);
        }}
        title={sort_type}
      >
        {column.title}
        <TableIcon
        context={context}
        theme={props.theme}
          name={sort_type}
          fileName={
            props.sortingData.dataIndex === column.dataIndex
              ? props.sortingData.direction === "asc"
              ? "ascending-18"
              : "descending-18"
              : "sortable-18"
          }
        />
      </SortableCellButton>
    );
  }

  function renderCheckboxFilter(context) {
    const options = props.selectionData.filter.map((filter) => {
      return {
        display: filter.title,
        value: filter.value,
      };
    });
    return (
      <DropDownList
        context={context}
        id={`dropdown_${generateId()}`}
        triggerType="checkbox"
        onGetSelectedOption={props.selectionData.onSelect}
        options={options}
        selectedOption={props.selectionData.selectedFilter}
      />
    );
  }

  const renderDisplayValue = (column: Column) => {
    if (column.displayValue) {
      return column.displayValue;
    } else {
      return column.title;
    }
  };

  return (
    <ThemeProvider.Consumer>
      {(context) => (
    <THeadComponent context={context} theme={props.theme}>
      <TRComponent context={context} theme={props.theme} headerType={props.headerType}>
        {props.selectionData ? (
          <THComponent context={context} theme={props.theme}
            columnWidth={props.selectionData?.columnWidth} >
            {props.selectionData.filter ? (
              renderCheckboxFilter(context)
            ) : (
              <CheckBox
                context={context}
                designSystem={props.designSystem}
                checked={props.selectionData.selectedAll}
                groupName="CheckBox-Group-One"
                id={`checkbox_${props.uuid}_header`}
                onCheckboxChange={() => {
                  props.selectionData.onSelect(
                    !props.selectionData.selectedAll === false ? "none" : "all"
                  );
                }}
                size="18"
                value={""}
                mixedState={props.selectionData.mixedState}
                ariaLabel="select all checkbox"
              >
                {!props.selectionData.hideSelectLabel && `Select all`}
              </CheckBox>
            )}
          </THComponent>
        ) : null}
        {props.columns.map((column, index) => {
          return (
            <THComponent
            context={context} theme={props.theme}
            columnWidth={column.width}
            sortable={column.sortable}
              key={column.key}
              role={column.sortable ? "status" : "columnheader"}
              style={column.width && { width: column.width }}
            >
              {column.sortable
                ? renderSortableCell(column, context)
                : renderDisplayValue(column)}
            </THComponent>
          );
        })}
      </TRComponent>
    </THeadComponent>
    )}
    </ThemeProvider.Consumer>
  );
};

const TableIcon = styled(Icon)`
  ${(props) => css`
    ${props.context?.styles ? props.context.styles.tableStyles.TableIcon : null}
  `}
`
const THeadComponent = styled.thead`
  ${(props) => css`
    ${props.context?.styles ? props.context.styles.tableStyles.THeadComponent : null}
  `}
`;

const TRComponent = styled.tr`
  ${(props) => css`
    ${props.context?.styles ? props.context.styles.tableStyles.TRComponent : null}
  `}
`;

const THComponent = styled.th`
  ${(props) => css`
    ${props.context?.styles ? props.context.styles.tableStyles.THComponent : null}
  `}
`;

const SortableCellButton = styled.button`
  ${(props) => css`
    ${props.context?.styles ? props.context.styles.tableStyles.SortableCellButton : null}
  `}
`;

export default TableHeader;
