import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import DropToUpload from 'react-drop-to-upload'
import UploaderContext from '../containers/UploaderContext'
import { dragAndDropHoverUrl, dragAndDropDefaultUrl } from '../const/imageUrls'
import styles from '../file-upload.module.scss'

export const FileUploaderView = ({ buttonLabel, fileTypes, currentDragOver, handleFileChange, isBrowseBtnDisabled,
  onDragLeave, filesAllowedLabel, onDrop, onDragEnter, modalEnabled,
  isSingleFileUpload, dropFileText, chooseFileText }) => {
  const context = useContext(UploaderContext)
  let acceptedFileTypes = fileTypes !== null ? fileTypes.toString() : ''
  let isErr = context.state.errorMessage !== ''
  let dragDivClass = modalEnabled ? `${styles.statUploadSquareModal} ${styles.squareBorder}`
    : styles.statUploadSquareModal
  if (currentDragOver) {
    dragDivClass += ` ${styles.currentDragOver}`
  }
  if (isErr) {
    dragDivClass += ` ${styles.errorLoadingOutline}`
  }
  return (
    <DropToUpload
      onDrop={onDrop}
      onOver={onDragEnter}
      onLeave={onDragLeave}
      className={dragDivClass}
      >
      <div className={styles.grid}>
        <div className={styles.groupLabelInitial}>
          {currentDragOver ? (
            <img id='dragImage' className={styles.dragImage} alt=''
              src={dragAndDropHoverUrl} />
          ) : (
            <img id='dropImage' className={styles.dragImage} alt='Drag files here to upload'
              src={dragAndDropDefaultUrl} />
          )}
        </div>
        <div className={styles.textLabel}>
          <h3 id='dragFileLabel' className={styles.orInitial}>
            {dropFileText}
          </h3>
          <div id='dragOrLabel' className={styles.dragLabelInitial}>
            {chooseFileText}
          </div>
          <div className={styles.uploaderInputInitial}>
            <input
              type='file'
              name={'fileUpload_' + context.state.id}
              id={'fileUpload_' + context.state.id}
              className={`${styles.fileInputHidden} ${styles.fileInputStyle}`}
              accept={acceptedFileTypes}
              onChange={handleFileChange}
              role='button'
              tabIndex='0'
              multiple={!isSingleFileUpload}
              disabled={isBrowseBtnDisabled}
            />
            <label
              htmlFor={'fileUpload_' + context.state.id}
              className={!isBrowseBtnDisabled ? styles.fileInputButtonBrowseStyle
                : `${styles.fileInputButtonBrowseStyle} ${styles.fileInputButtonDisabledStyle}`}
              aria-label='Click button to browse files from your computer.'
              tabIndex='-1'
            >
              {buttonLabel}
            </label>
          </div>
        </div>
      </div>
      <div className={styles.filesAllowedLabelInitial}>
        {filesAllowedLabel}
      </div>
    </DropToUpload>
  )
}

FileUploaderView.propTypes = {
  handleFileChange: PropTypes.func, // function to fully handle the file upload for drop or file change
  filesAllowedLabel: PropTypes.object, // message to the user for the files allowed
  fileTypes: PropTypes.array, // file type restriction for the input component.  Options: images or data
  currentDragOver: PropTypes.bool.isRequired,
  buttonLabel: PropTypes.string, // label for the browse button
  onDrop: PropTypes.func.isRequired,
  onDragLeave: PropTypes.func.isRequired,
  onDragEnter: PropTypes.func.isRequired,
  modalEnabled: PropTypes.bool.isRequired,
  isBrowseBtnDisabled: PropTypes.bool.isRequired,
  isSingleFileUpload: PropTypes.bool.isRequired,
  dropFileText: PropTypes.string,
  chooseFileText: PropTypes.string
}
FileUploaderView.defaultProps = {
  dropFileText: 'Drop files here',
  chooseFileText: 'or'
}
export default FileUploaderView
