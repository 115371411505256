/* eslint-disable @typescript-eslint/ban-types */
import React, { FunctionComponent } from 'react';
import styled, { css } from "styled-components";
import Icon from '../Icon';
import ThemeProvider from "./../ThemeProvider";
import FocusTrap from 'focus-trap-react'

type Props = {
  variant: string; // Default, Success , Danger
  designSystem: string;
  id: string;
  heading?: string;
  body: string;
  dismissible: boolean;
  onClose: (status: boolean, event: Event) => void;
  showIcon: boolean;
  iconName: string;
  show: boolean;
  focusTrap: boolean;
  className?: string;
};

const Alert: FunctionComponent<Props> = (props) => {

  const handleClose = (e) => {
    props.onClose(false, e);
  };

  const icons =
  {
    "gravity": {
      "Default": "information-24",
      "Success": "check-lg-24",
      "Danger": "warning-24"
    },
    "missionzero": {
      "Default": "information-24",
      "Success": "single-correct-ns-24",
      "Danger": "warning-24"
    }
  }

  const populateIcon = (context) => {
    switch (props.variant) {
      case "Default":
        return (
          <AlertIcon
            fileName={props.iconName ? props.iconName : icons[props.designSystem][props.variant]}
            isHidden={true}
            color={"#047A9C"}
            variant={props.variant}
            context={context}
          />
        );
      case "Success":
        return (
          <AlertIcon
            fileName={props.iconName ? props.iconName : icons[props.designSystem][props.variant]}
            isHidden={true}
            color={"#038238"}
            variant={props.variant}
            context={context}
          />
        );
      case "Danger":
        return (
          <AlertIcon
            fileName={props.iconName ? props.iconName : icons[props.designSystem][props.variant]}
            isHidden={true}
            color={"#ca4644"}
            variant={props.variant}
            context={context}
          />
        );
    }
  };

  return (
    <ThemeProvider.Consumer>
      {(context) => (
        props.show && (
          <FocusTrap active={props.focusTrap}>
            <AlertDialog
              {...props}
              context={context}
              role="alertdialog"
              id={props.id !== undefined ? props.id : null}
              aria-modal="true"
            >
              {props.dismissible && (
                <CloseButton
                  onClick={handleClose}
                  type="button"
                  aria-label="Close alert"
                  context={context}
                >
                  <Icon fileName={"incorrect-18"} color={"#6a7070"} title={"close"} />
                </CloseButton>
              )}
              <AlertContent context={context} >
                {props.showIcon ? populateIcon(context) : null}
                <AlertText context={context}>
                  {props.heading && (
                    <AlertHeading context={context} variant={props.variant} >{props.heading}</AlertHeading>
                  )}
                  {props.body && (
                    <AlertBody context={context} variant={props.variant} >{props.body}</AlertBody>
                  )}
                </AlertText>
              </AlertContent>
            </AlertDialog>
          </FocusTrap>
        )
      )}
    </ThemeProvider.Consumer>
  );
};

const AlertDialog = styled.div`
  ${(props) => css`
    ${props.context && props.context.styles ? props.context.styles.alertStyles.alertDialog : null}
  `}
`

const CloseButton = styled.button`
  ${(props) => css`
    ${props.context && props.context.styles ? props.context.styles.alertStyles.closeButton : null}
  `}
`

const AlertContent = styled.div`
  ${(props) => css`
    ${props.context && props.context.styles ? props.context.styles.alertStyles.alertContent : null}
  `}
`

const AlertIcon = styled(Icon)`
  ${(props) => css`
    ${props.context && props.context.styles ? props.context.styles.alertStyles.alertIcon : null}
  `}
`

const AlertText = styled.div`
  ${(props) => css`
    ${props.context && props.context.styles ? props.context.styles.alertStyles.alertText : null}
  `}
`

const AlertHeading = styled.h1`
  ${(props) => css`
    ${props.context && props.context.styles ? props.context.styles.alertStyles.alertHeading : null}
  `}
`

const AlertBody = styled.p`
  ${(props) => css`
    ${props.context && props.context.styles ? props.context.styles.alertStyles.alertBody : null}
  `}
`

Alert.defaultProps = {
  variant: "Default",
  designSystem: "gravity",
  dismissible: true,
  show: true,
  showIcon: true,
  focusTrap: false
};

export default Alert;
