import React from "react";
import DirectoryHeader from "./DirectoryHeader.tsx";
import styles from "./directory.module.scss";
import CheckBox from "./../CheckBox";
import Icon from "./../Icon";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Dropzone from "react-dropzone";
import { generateId } from "./../_util/common.js";
import ThemeProvider from "./../ThemeProvider";

type Column = {
  title: string;
  dataIndex: string;
  key: number;
  width?: string | number;
  displayValue?: any;
  sortable?: boolean;
  tooltip?: boolean;
  tooltipText?: string;
};

enum SortingOrder {
  asc = "asc",
  desc = "desc",
  sortable = "sortable",
}

enum dateType {
  date = "date",
}

enum Theme {
  default = "default",
  wsol = "wsol",
}

type Row = {
  key: number;
  droppable?: boolean;
};

type Option = {
  value: number;
  display: number;
};

type sortingValues = {
  dataIndex: string;
  direction: string;
};

type Filter = {
  title: string;
  value: string;
  logic: (record: Row) => Row;
};

type Props = {
  captionTitle?: string;
  columns: Column[];
  dataSource: Row[];
  rowSelection?: {
    onSelect: (selectedRows: Row[]) => void;
    selectedRows?: number[];
    filter?: Filter[];
    hideSelectLabel?: boolean;
    columnWidth?: string;
    selectedColor?: string;
    disabledRows?: number[];
  };
  optionList?: Option[];
  onClickRow?: (data: Row) => null;
  sorting?: {
    onChangeSorting?: (sortingData: sortingValues, rowsPerPage: number) => void;
    dataIndex: string;
    direction: string;
  };
  theme?: Theme;
  rowHoverColor?: string;
  fileUpload?: {
    enabled?: boolean;
    onFilesDrop?: (files: any, location: any) => null;
  };
  fileReorder?: {
    enabled?: boolean;
    onDragEnd?: (result: any) => null;
  };
};

type State = {
  columns: Column[];
  dataSource: Row[];
  selection: {
    selectedRows: number[];
    selectedAll: boolean;
    selectedFilter?: string;
    hideSelectLabel?: boolean;
    columnWidth?: string;
    disabledRows: number[];
  };
  lastPageRows?: number;
  dataSourceChunk?: Row[];
  dataOnDemand: boolean;
  uuid: string;
};

class Directory extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      columns: props.columns,
      dataSource: props.dataSource,
      selection: {
        selectedRows:
          (props.rowSelection && props.rowSelection.selectedRows) || [],
        selectedAll: false,
        selectedFilter: null,
        hideSelectLabel:
          (props.rowSelection && props.rowSelection.hideSelectLabel) || false,
        columnWidth:
          props.rowSelection && props.rowSelection.columnWidth
            ? props.rowSelection.columnWidth
            : "",
        disabledRows:
          (props.rowSelection && props.rowSelection.disabledRows) || [],
      },
      dataSourceChunk: [],
      lastPageRows: null,
      dataOnDemand: false,
      uuid: generateId(),
    };
  }

  sortData = (data: Row[], dataIndex: string, direction: string) => {
    if (dataIndex && direction) {
      if (dataIndex === dateType.date) {
        data.sort(function (a, b) {
          var key1 = new Date(a.date);
          var key2 = new Date(b.date);

          if (key1 < key2) {
            return direction == SortingOrder.asc ? -1 : 1;
          } else if (key1 == key2) {
            return 0;
          } else {
            return direction == SortingOrder.asc ? 1 : -1;
          }
        });
      } else {
        data.sort(this.nullSort(direction === SortingOrder.desc, dataIndex));
      }
    }

    return data;
  };

  nullSort = (descending = false, dataIndex: string) => {
    const naturalCollator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: "base",
    });

    return function (a, b) {
      if (
        (a[dataIndex].value || a[dataIndex]) ===
        (b[dataIndex].value || b[dataIndex])
      ) {
        return 0;
      }
      if (a[dataIndex] === null || a[dataIndex].value === null) {
        return 1;
      }
      if (b[dataIndex] === null || b[dataIndex].value === null) {
        return -1;
      }

      let ret;

      ret = naturalCollator.compare(
        a[dataIndex].value || a[dataIndex],
        b[dataIndex].value || b[dataIndex]
      );

      if (descending) {
        ret = -ret;
      }

      return ret;
    };
  };

  onSort = (dataIndex: string) => {
    const sorting = {
      dataIndex: dataIndex,
      direction:
        dataIndex === this.props.sorting.dataIndex
          ? this.props.sorting.direction === SortingOrder.asc
            ? SortingOrder.desc
            : SortingOrder.asc
          : SortingOrder.asc,
    };

    if (this.props.sorting && this.props.sorting.onChangeSorting) {
      this.props.sorting.onChangeSorting(sorting, 0);
    }
  };

  renderTableRow = (data: Row) => {
    const row = this.props.columns.map((col, index) => {
      return (
        <td
          className={styles[`table_${this.props.theme}_body_cell`]}
          key={index}
          style={col.width && { width: col.width }}
        >
          {data[col.dataIndex] && data[col.dataIndex].displayValue
            ? data[col.dataIndex].displayValue
            : data[col.dataIndex]}
        </td>
      );
    });
    return row;
  };

  generateSelectedRowsByFilterValue = (status: string) => {
    const logic =
      this.props.rowSelection.filter &&
      this.props.rowSelection.filter.find((row) => {
        return row.value === status;
      });

    const { disabledRows } = this.state.selection;

    const response =
      status === "all"
        ? this.state.dataSource.filter(
            (data) => !disabledRows.includes(data.key)
          )
        : status === "none"
        ? []
        : this.state.dataSource.filter(logic.logic);

    return response;
  };

  generateSelectedRowsByFilter = (status: string) => {
    const logic =
      this.props.rowSelection.filter &&
      this.props.rowSelection.filter.find((row) => {
        return row.value === status;
      });

    const response =
      status === "all"
        ? this.state.dataSource.map((data) => {
            return data;
          })
        : status === "none"
        ? []
        : this.state.dataSource.filter(logic.logic);

    return response;
  };

  generateSelectedRowKeysByFilter = (filter: Filter) => {
    const responseArray = this.state.dataSource.filter(filter.logic);
    const responseArrayKeys = responseArray.map((row) => row.key);

    return responseArrayKeys;
  };

  generateSelectedOption = (selection, filters: Filter[]) => {
    let response = "";

    for (let i = 0; i < filters.length; i++) {
      const filteredArray = this.generateSelectedRowKeysByFilter(filters[i]);
      if (
        JSON.stringify(filteredArray.sort()) ===
        JSON.stringify(selection.selectedRows.sort())
      ) {
        response = filters[i].value;
        break;
      }
    }
    return response;
  };

  onSelect = (key: number) => {
    let newSelection = {
      selectedRows: this.state.selection.selectedRows,
      selectedAll: false,
      selectedFilter: null,
      hideSelectLabel: this.state.selection.hideSelectLabel,
      disabledRows: this.state.selection.disabledRows,
    };

    const index = newSelection.selectedRows.findIndex(
      (element) => element === key
    );
    if (index < 0) {
      newSelection.selectedRows.push(key);
    } else {
      newSelection.selectedRows.splice(index, 1);
    }

    newSelection.selectedAll =
      newSelection.selectedRows.length ===
      this.state.dataSource.length - this.state.selection.disabledRows.length;

    if (this.props.rowSelection.filter !== undefined) {
      newSelection.selectedFilter = newSelection.selectedAll
        ? "all"
        : newSelection.selectedRows.length === 0
        ? "none"
        : this.generateSelectedOption(
            newSelection,
            this.props.rowSelection.filter
          );
    }

    this.setState(
      {
        selection: newSelection,
      },
      () => {
        let responseArray = this.state.dataSource.map((data) => {
          if (newSelection.selectedRows.includes(data.key)) {
            return data;
          }
        });
        responseArray = responseArray.filter(function (e) {
          return e;
        });
        this.props.rowSelection.onSelect(responseArray);
      }
    );
  };

  renderSelectBox = (data: Row, index: number) => {
    const { selectedRows, disabledRows, hideSelectLabel } =
      this.state.selection;
    return (
      <td className={styles[`table_${this.props.theme}_body_cell`]}>
        <CheckBox
          checked={selectedRows.includes(data.key)}
          id={`checkbox_${this.state.uuid}_${index}`}
          value={data.key}
          onCheckboxChange={(val, e) => {
            this.onSelect(data.key);
          }}
          size="18"
          disabled={disabledRows.includes(data.key)}
          ariaLabel={`select Row ${data.name.value}`}
        >
          {!hideSelectLabel && `select row ${index + 1}`}
        </CheckBox>
      </td>
    );
  };

  sendDataSourceChunk = (
    pageNumber: number,
    rowsPerPage: number,
    dropdownOption: number
  ) => {
    if (this.state.dataOnDemand) {
      return this.state.dataSource;
    } else {
      let start = (pageNumber - 1) * dropdownOption;
      let end = (pageNumber - 1) * dropdownOption + Number(rowsPerPage);
      let dataChunk = this.state.dataSource.slice(start, end);
      return dataChunk;
    }
  };

  selectAllRows = (status: string) => {
    const selectedRows = this.generateSelectedRowsByFilterValue(status);

    const selectedRowKeys = selectedRows.map((row) => row.key);

    const selectionData = {
      selectedAll: status === "all" ? true : false,
      selectedRows: selectedRowKeys,
      selectedFilter: status,
      hideSelectLabel: this.state.selection.hideSelectLabel,
      disabledRows: this.state.selection.disabledRows,
    };
    this.setState(
      {
        selection: selectionData,
      },
      () => {
        this.props.rowSelection.onSelect(selectedRows);
      }
    );
  };

  renderBodyWithoutPagination = () => {
    return this.state.dataSource.map((data, index) => {
      const active = this.state.selection.selectedRows.includes(data.key);
      return (
        <Dropzone
          noDragEventsBubbling={true}
          onDrop={(files) => {
            this.onDrop(files, data);
          }}
          disabled={!this.props.fileUpload.enabled}
        >
          {({ getRootProps, isDragActive }) => (
            <Draggable
              key={data.key}
              draggableId={`draggable-${data.key}`}
              index={index}
              isDragDisabled={!this.props.fileReorder.enabled}
            >
              {(provided, snapshot) => (
                <tr
                  {...getRootProps({ className: "dropzone" })}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  onClick={() =>
                    this.props.onClickRow && this.props.onClickRow(data)
                  }
                  className={`${styles[`table_${this.props.theme}_body_row`]} ${
                    active
                      ? styles[`table_${this.props.theme}_body_row_selected`]
                      : ""
                  } ${
                    active &&
                    this.state.dataSource[index + 1] &&
                    !this.state.selection.selectedRows.includes(
                      this.state.dataSource[index + 1].key
                    )
                      ? styles[
                          `table_${this.props.theme}_body_row_selected_before_active`
                        ]
                      : ""
                  }`}
                  key={index}
                  tabIndex={this.props.onClickRow ? 0 : -1}
                  role={this.props.onClickRow ? "button" : ""}
                  onKeyPress={(event) => {
                    if (event.key === "Enter" && this.props.onClickRow) {
                      this.props.onClickRow(data);
                    }
                  }}
                >
                  {
                    <td
                      className={styles[`table_${this.props.theme}_body_cell`]}
                      {...provided.dragHandleProps}
                    >
                      <Icon
                        fileName="drag-handle-18"
                        name="drag-handle-18"
                        title="drag-handle"
                        color="#6a7070"
                        ariaLabelText={data.name.value}
                      ></Icon>
                    </td>
                  }
                  {this.props.rowSelection
                    ? this.renderSelectBox(data, index)
                    : null}
                  {this.renderTableRow(data)}
                </tr>
              )}
            </Draggable>
          )}
        </Dropzone>
      );
    });
  };

  updateSelectedRows = (selectedRows, dataSource) => {
    const found = selectedRows.filter((r) =>
      dataSource.find((x) => x.key === r)
    );
    return found;
  };

  updateDisabledRows = (disabledRows, dataSource) => {
    const dRows = disabledRows.filter((r) =>
      dataSource.find((x) => x.key === r)
    );
    return dRows;
  };

  removeAttributesInElements = () => {
    //Removal of role="button" attribute in table container
    let divItem = document.getElementsByClassName(
      `${styles[`table_${this.props.theme}_container`]}`
    );
    let arrayitems = divItem[0];
    arrayitems.removeAttribute("role");
    arrayitems.removeAttribute("tabindex");

    //Removal of role attribute in tr element
    let arraylist = [];
    let arrayItemspart = document.getElementsByClassName(
      `${styles[`table_${this.props.theme}_body_row`]}`
    );
    for (let i = 0; i < arrayItemspart.length; i++) {
      arrayItemspart[i].removeAttribute("role");
    }
  };

  componentDidMount() {
    if (this.props.rowSelection) {
      let newSelection = {
        selectedRows: this.state.selection.selectedRows,
        selectedAll: false,
        selectedFilter: null,
        hideSelectLabel: this.state.selection.hideSelectLabel,
        disabledRows: this.state.selection.disabledRows,
      };

      newSelection.selectedAll =
        newSelection.selectedRows.length ===
        this.state.dataSource.length - this.state.selection.disabledRows.length;
      if (this.props.rowSelection.filter !== undefined) {
        newSelection.selectedFilter = newSelection.selectedAll
          ? "all"
          : newSelection.selectedRows.length === 0
          ? "none"
          : this.generateSelectedOption(
              newSelection,
              this.props.rowSelection.filter
            );
      }

      this.setState({
        selection: newSelection,
      });
    }
    this.removeAttributesInElements();
  }

  componentDidUpdate(prevProps) {
    let newSelection = {
      selectedRows: this.updateSelectedRows(
        this.state.selection.selectedRows,
        this.props.dataSource
      ),
      selectedAll: this.state.selection.selectedAll,
      selectedFilter: this.state.selection.selectedFilter,
      hideSelectLabel: this.state.selection.hideSelectLabel,
      disabledRows: this.updateDisabledRows(
        this.state.selection.disabledRows,
        this.props.dataSource
      ),
    };

    newSelection.selectedAll =
      newSelection.selectedRows.length ===
      this.props.dataSource.length - newSelection.disabledRows.length;

    let sortedDataSource = [...this.props.dataSource];

    if (
      prevProps.dataSource !== this.props.dataSource ||
      prevProps.sorting.dataIndex !== this.props.sorting.dataIndex ||
      prevProps.sorting.direction !== this.props.sorting.direction
    ) {
      sortedDataSource = this.sortData(
        sortedDataSource,
        this.props.sorting.dataIndex,
        this.props.sorting.direction
      );

      this.setState({
        dataSource: sortedDataSource,
        selection: newSelection,
      });
    }
    this.removeAttributesInElements();
  }

  onDragEnd = (result) => {
    this.props.fileReorder.onDragEnd(result);
  };

  onDrop = (files, container) => {
    this.props.fileUpload.onFilesDrop(files, container);
  };

  render() {
    const { fileUpload, captionTitle } = this.props;
    return (
      <ThemeProvider.Consumer>
        {(context) => (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Dropzone
              onDrop={(files) => {
                this.onDrop(files, "main");
              }}
              disabled={!fileUpload.enabled}
            >
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div
                  {...getRootProps({ className: "dropzone" })}
                  className={`${styles[`table_${this.props.theme}_container`]}`}
                  style={isDragActive ? { border: "2px solid #1977D4" } : {}}
                >
                  <table
                    className={`${styles[`table_${this.props.theme}`]} ${
                      this.props.className
                    }`}
                  >
                    <caption className="visually-hidden">{captionTitle}</caption>
                    <DirectoryHeader
                      columns={this.state.columns}
                      sortingData={{
                        ...this.props.sorting,
                        ...{ onSort: this.onSort },
                      }}
                      selectionData={
                        this.props.rowSelection
                          ? {
                              onSelect: this.selectAllRows,
                              selectedAll:
                                this.state.selection.selectedRows.length > 0 &&
                                this.state.selection.selectedAll,
                              mixedState:
                                !this.state.selection.selectedAll &&
                                this.state.selection.selectedRows.length > 0,
                              filter: this.props.rowSelection.filter,
                              selectedFilter:
                                this.state.selection.selectedFilter,
                              hideSelectLabel:
                                this.state.selection.hideSelectLabel,
                              columnWidth: this.state.selection.columnWidth,
                            }
                          : null
                      }
                      theme={this.props.theme}
                    ></DirectoryHeader>
                    <Droppable droppableId="droppable" isCombineEnabled>
                      {(provided, snapshot) => (
                        <tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {this.renderBodyWithoutPagination()}
                        </tbody>
                      )}
                    </Droppable>
                  </table>
                </div>
              )}
            </Dropzone>
          </DragDropContext>
        )}
      </ThemeProvider.Consumer>
    );
  }

  public static defaultProps = {
    optionList: [
      { value: 10, display: 10 },
      { value: 50, display: 50 },
      { value: 100, display: 100 },
    ],
    theme: Theme.default,
    fileUpload: {
      enabled: false,
      onFilesDrop: (files, location) => {
        console.log(files, location);
      },
    },

    fileReorder: {
      enabled: false,
      onDragEnd: (result) => {
        console.log(result);
      },
    },
    sorting: {
      onChangeSorting: (sortingData: sortingValues, rowsPerPage: number) => {
        /**/
      },
      dataIndex: null,
      direction: SortingOrder.sortable,
    },
  };
}

export default Directory;
