import React from 'react';
import MessageBox from './MessageBox';
import ReactDOM from 'react-dom';

const Message = () => {
    const Success = (body, heading, closeTime) => {
        return ReactDOM.render(
            <MessageBox variant='Success' body={body} heading={heading} closeTime={closeTime}></MessageBox>,
            document.createElement('alert_box_div')
        );
    };

    const Default = (body, heading, closeTime) => {
        return ReactDOM.render(
            <MessageBox variant='Default' body={body} heading={heading} closeTime={closeTime}></MessageBox>,
            document.createElement('alert_box_div')
        );
    };

    const Danger = (body, heading, closeTime) => {
        return ReactDOM.render(
            <MessageBox variant='Danger' body={body} heading={heading} closeTime={closeTime}></MessageBox>,
            document.createElement('alert_box_div')
        );
    };

    return {
        Danger,
        Default,
        Success,
    };
};

export { Message };

export default Message();
