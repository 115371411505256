export const CLOSE = 'CLOSE'
export const DONE = 'DONE'
export const OPEN = 'OPEN'
export const MINIMIZE = 'MINIMIZE'
export const DEFAULT_MAXIMUM_FILE_SIZE = 2097152
export const ACCEPTED_FILE_TYPES_LABEL = 'Accepted file types: '
export const MAX_FILE_SIZE_LABEL = 'Max file size = '
export const MAX_FILE_AMOUNT_LABEL = 'Max file amount = '
export const UPLOADING = 'UPLOADING'
export const COMPLETED = 'UPLOADED'
export const PROCESSING = 'PROCESSING'

/** Error messages */
export const FILE_UPLOAD_ERR_MSG = `Your files weren't uploaded.` +
  ` To complete the upload, please check the upload guidelines`
export const GENERAL_ERR_MSG = 'Something went wrong on our end. Please try uploading again'
export const UNSUPPORTED_CHAR = 'File name contains unsupported characters \\n * ? \\ " < > | # : Please rename your file'
/** Error messages */

export const BROWSE = 'Browse'
export const UPLOAD_LABEL = 'Upload Files'
