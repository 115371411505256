/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2018 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PaginationButton from './common/PaginationButton'
import './Pagination.scss'

const PREVIOUS = 'previousPage'
const NEXT = 'nextPage'

class CompactPaginationByPage extends Component {
  constructor (props) {
    super(props)
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)

    this.state = {
      pageChanged: true,
      currentPage: this.props.currentPageNum
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.currentPageNum !== this.props.currentPageNum) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(prevState => ({
        currentPage: this.props.currentPageNum,
        pageChanged:true
      }))
    }
  }

  nextPage () {
    this.setState({ pageChanged:true }) // trigerring state change for rerender
    this.setState(prevState => ({
      currentPage: prevState.currentPage + 1
    }), () => {
      this.props.handlePage(this.state.currentPage, NEXT)
    })
  }

  previousPage () {
    this.setState({ pageChanged:true }) // trigerring state change for rerender
    this.setState(prevState => ({
      currentPage:  prevState.currentPage - 1
    }), () => {
      this.props.handlePage(this.state.currentPage, PREVIOUS)
    })
  }

  render () {
    let { totalPages, consumer, className} = this.props
    let backAriaLabel = this.props.backAriaLabel
    let nextAriaLabel = this.props.nextAriaLabel

    if (!(this.state.currentPage === 0 || this.state.currentPage > totalPages)) {
      return (
        <div className='ls-pagination' role='navigation' aria-label='pagination'>
          <PaginationButton ariaLabel={backAriaLabel}
            className={className}
            isDisabled={this.state.currentPage === 1}
            onClick={this.previousPage} iconFileName='next-18' iconName='back-page'
            iconTitle='Prior Page' buttonId='prevPage' svgClass='back-svg' />

          <span className={consumer !== "MSNG" ? 'ls-compact-text' : 'ls-compact-text ls-pagination-bold'}
            aria-live='polite' role='alert'>
            <span className='pageDetails'>
              {consumer !== "MSNG" ?  <span> Page </span> : null}
              {this.state.pageChanged && this.state.currentPage}
              <span> of </span>
              {totalPages}
            </span>
          </span>

          <PaginationButton ariaLabel={nextAriaLabel}
            consumer={consumer}
            className={className}
            isDisabled={this.state.currentPage === totalPages}
            onClick={this.nextPage} iconFileName='next-18' iconName='next-page'
            iconTitle='Next Page' buttonId='nexPage' />

        </div>
      )
    } else {
      return (
        null
      )
    }
  }
}

CompactPaginationByPage.propTypes = {
  currentPageNum: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handlePage: PropTypes.func,
  consumer: PropTypes.string,
  className: PropTypes.string,
  backAriaLabel: PropTypes.string,
  nextAriaLabel: PropTypes.string
}
CompactPaginationByPage.defaultProps = {
  handlePage: () => {/**/},
  currentPageNum: 1,
  consumer:"Default"
}

export default CompactPaginationByPage
