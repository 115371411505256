// LIMITS:
// editor style is hard coded
// no more than one editor on the same page
// icons not working

import React, { Component } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";

import styles from "./matheditor.module.scss";
import Button from "../Button";
window.$ = window.jQuery;

class MathEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { empty: true, editor: undefined, editorAPI: undefined, mathEditorLoadedPreviously: false };
    this.response = "";
  }

  toMathML = (jax, callback) => {
    callback(jax.originalText);
  };

  convert = (AjaxText) => {
    const that = this;
    const tempDiv = window
      .$('<div style="display:none"></div>')
      .appendTo("body")
      .html(AjaxText)[0];
    window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, tempDiv]); //first place in Q
    let response = "";
    window.MathJax.Hub.Queue(function () {
      //wait for a callback to be fired
      const jax = window.MathJax.Hub.getAllJax(tempDiv);
      for (let i = 0; i < jax.length; i++) {
        that.toMathML(jax[i], function (mml) {
          response = mml;
        });
      }
      window.$(tempDiv).remove();
      response = response.replace(/\(/g, ""); //notice this escape character for ( - i.e it has to be \( , know why it is beacuse JS will treat ) or ( as end/begin of function as there are no quotes here.
      response = response.replace(/\)/g, ""); //notice this escape character for ) - i.e it has to be \)
      response = response.replace(/\\/g, "");
      return response;
    });
  };

  componentDidMount() {
    window.addEventListener("message", this.handleEditorUpdate);
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.handleEditorUpdate);
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      if (this.state.editor) {
        this.state.editor.setDocumentContent(this.props.content);
      }
      document.addEventListener("keyup", this.handleKeyUpEvents, false);
    } else if (!this.props.isOpen && prevProps.isOpen) {
      setTimeout(
        () =>
          document.removeEventListener("keyup", this.handleKeyUpEvents, false),
        200
      );
    }
  }

  handleCloseEditor = () => {
    this.setState({
      empty: true,
    });
    this.props.closeEditor();
  };

  handleKeyUpEvents = (e) => {
    e.stopPropagation();
  };

  getMathFrame = () => {
    const frames = window.frames;
    let response = false;
    for (let i = 0; i < frames.length; i++) {
      if (frames[i].EquationEditorAPI !== undefined) {
        response = frames[i];
      }
    }
    return response;
  };

  handleEditorUpdate = (event) => {
    if (event.data && event.data.message === "mathEditorUpdated") {
      const content = JSON.parse(event.data.content);
      const present = JSON.parse(event.data.present);
      if (this.isEmpty(content)) {
        this.setState({
          empty: true,
          content: content,
          present: present,
        });
      } else {
        if(this.state.mathEditorLoadedPreviously){
          this.setState({
            empty: false,
            content: content,
            present: present,
          });
        }else{
          this.setState({
            empty: true,
            content: content,
            present: present,
            mathEditorLoadedPreviously: true
          });
        }
      }
    } else if (event.data === "editorLoaded") {
      if (this.props.content) {
        const matheditorwindow = document.getElementById(
          "lassmatheditorcontainer"
        ).contentWindow;
        matheditorwindow.postMessage(JSON.stringify(this.props.content), "*");
      }
      this.setState({
        empty: true,
        mathEditorLoadedPreviously: false
      });
    }
  };

  isEmpty = (content) => {
    content = content.replace(/\r?\n|\r/g, "").replace(/ /g, "");
    return (
      content.match(/<body><p><input\/><\/p><\/body>/) ||
      content.match(
        new RegExp(
          [
            '<mathxmlns="http://www.w3.org/1998/Math/MathML">',
            '<apply><csymbolcd="basic">box</csymbol></apply></math>',
          ].join("")
        )
      )
    );
  };

  randString() {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  }

  insertExpression = () => {
    const cmml = this.state.content;
    const pmml = this.state.present;
    const mlId = this.randString();

    window.$.data(document.body, "cmml", cmml);
    window.$.data(document.body, "mlId", mlId);
    window.$.data(document.body, mlId, cmml);
    window.$.data(document.body, "modify", null);
    this.props.setContent(pmml, cmml, pmml, mlId, this.props.contentId);
    this.handleCloseEditor();
  };

  render() {
    const { isOpen } = this.props;
    const { empty } = this.state;

    const customStyles = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(37, 37, 37, 0.6)",
        zIndex: 2000,
      },
    };

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        contentLabel=""
        style={customStyles}
        className={`${styles.matheditor_modal}`}
        role="dialog"
        onRequestClose={this.handleCloseEditor}
      >
        <div
          id="math-editor-template"
          className={styles.matheditor_modal_content}
        >
          <iframe
            title="math editor iframe"
            onKeyUp={this.handleCloseEditor}
            id="lassmatheditorcontainer"
            src="https://learningapps-ui-ppe.pearson.com/b0/qa-int/lass-ui-cdn-libs/latest/matheditor/html/matheditor.html"
            style={{ height: "500px", width: "100%", border: "none" }}
          />
          <div className={styles.matheditor_modal_actions}>
            <Button onClick={this.handleCloseEditor} size="small">
              Cancel
            </Button>
            <Button
              onClick={this.insertExpression}
              disabled={empty}
              varient="primary"
              size="small"
            >
              Insert expression
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

MathEditor.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
  closeEditor: PropTypes.func.isRequired,
  setContent: PropTypes.func.isRequired,
};

export default MathEditor;
