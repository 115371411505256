import React from 'react'
import PropTypes from 'prop-types'

import styles from './_regiondrawing.module.scss'

// used for region question type
// NOTE: coordinates are saved on a 0 - 100 scale.  100 = 100% of the width or height. 0 = 0% of the width or height
class RegionDrawing extends React.Component {
  // valid types: 'questionCreator', 'hotspotCreator', 'directionCreator', 'directionResponse', 'response',
  // 'correctAnswer' or 'instructorResults'
  static propTypes = {
    imagePreviewUrl: PropTypes.string,
    allRegionsRequired: PropTypes.bool,
    correctRegions: PropTypes.array, // instructor defined regions
    studentResponses: PropTypes.array, // responses from the student
    mouseUpKey: PropTypes.number, // increments anytime the mouse up occurs on container
    addRegion: PropTypes.func,
    questionType: PropTypes.string, // REGION, HOTSPOT, or DIRECTION
    type: PropTypes.string,
    moveChangeRegionPoint: PropTypes.func,
    clearRegions: PropTypes.func,
    deletePoint: PropTypes.func, // only used for the responses
    redrawKey: PropTypes.number, // used to force a redraw
    numberRegions: PropTypes.number, // for responses, the total number of regions
    handleAllRegionsChange: PropTypes.func,
    noInteraction: PropTypes.bool,
    tolerance: PropTypes.number, // used to determine how much tolerence to draw for the hotspot question type
    alternateText: PropTypes.string,
    showRegionError: PropTypes.bool,
    hideFullAnswer: PropTypes.bool, // used for a specific case on the student side
    setRegionTolerance: PropTypes.func, // used for hotspot and direction
    zoomPerc: PropTypes.number, // used for zooming in and out
    updateRegionSizeKey: PropTypes.number, // change will force a resizing
    uniqueId: PropTypes.string, // used for resizing to measure the container
    provideAnswer: PropTypes.bool // true for case where the points should be colored based on correct answer
  }
  constructor (props) {
    super(props)
    this.state = {
      imageWidth: '',
      imageHeight: '',
      showImage: false // show the image off screen to get it's sizing
    }
    this.firstImageLoad = true
    this.canvas = null
    this.ctx = null
    // this.props.correctRegions will store all points added via an x and y value.
    // Outer array is for the regions, inner arrays is for the points in each region
    this.currentRegionNumber = 0
    this.currentlyMovingPoint = false // true when a point is being dragged
    this.currentPointIndex = 0 // will be the index for the point being edited currently
    this.firstDraw = true // used in case there are saved regions but nothing has been drawn yet
    this.originalPointLoc = null // used for the student side to determine if the point moved
    this.clickStartTime = null // used to ensure the delete click was in a short time frame
    this.maxWidth = 800
    this.maxWidthStudent = 800
    this.maxWidthAuthoring = 500
    this.maxWidthResults = 600
    this.containerWidth = 0 // width of the containing div
    this.originalImageWidth = 0
    this.originalImageHeight = 0
    this.addingNewPoint = false
    this.mobileScenario = false  // true when the user is using a touch based device
  }
  componentDidUpdate (previousProps) {
    this.checkDataProps(previousProps)
  }

  componentDidMount () {
    this.canvas = this.refs.drawingCanvas
    if (this.canvas != null) {
      this.ctx = this.canvas.getContext('2d')
      window.addEventListener('resize', this.updateMaxSize)
      this.updateMaxSize()
    }
  }
  componentWillUnmount () {
    window.removeEventListener('resize', this.updateMaxSize)
  }
  checkDataProps = (previousProps) => {
    const { correctRegions, mouseUpKey, type, numberRegions, zoomPerc, tolerance,
      studentResponses, imagePreviewUrl, redrawKey, updateRegionSizeKey } = this.props
    if (imagePreviewUrl && imagePreviewUrl.trim().length > 0) {
      if (imagePreviewUrl !== previousProps.imagePreviewUrl) {
        // case where a new image is loaded
        // reset the image size ratio when onImgLoad is hit
        this.firstImageLoad = true
        // make sure to check original sizing of the image
        this.setState({
          imageWidth: '',
          imageHeight: '',
          showImage: false // show the image off screen to get it's sizing
        })
      } else if (zoomPerc !== previousProps.zoomPerc) {
        this.resizeImage()
      } else {
        if (mouseUpKey !== previousProps.mouseUpKey) { // true when parent ends a mouse click
          // stop drawing on the move
          this.currentlyMovingPoint = false
          // reset the current region to be the last region
          if (type === 'response') {
            this.currentRegionNumber = studentResponses.length
            if (this.currentRegionNumber === numberRegions) {
              // don't allow more than the total number of regions
              this.currentRegionNumber = numberRegions - 1
            }
          } else if (type === 'questionCreator' || type === 'correctAnswer') {
            this.currentRegionNumber = correctRegions.length - 1
          }
        }
        // case where the question shifted back to region and there was a prior drawing
        const drawQuestionCreator = ((type === 'questionCreator' || type === 'correctAnswer') &&
          correctRegions != null && correctRegions.length > 0 && correctRegions[0].length > 1)
        const drawStudentResponses =
        (type === 'response' || type === 'instructorResults' || type === 'hotspotCreator' ||
          type === 'directionCreator' || type === 'directionResponse') &&
          studentResponses != null && studentResponses[0] != null
        if (updateRegionSizeKey !== previousProps.updateRegionSizeKey) {
          // resize the image
          const me = this
          // needs a delay as the live queue is resizing.
          // wait until the resize is done
          setTimeout(() => { me.updateMaxSize() }, 200)
        } else if (this.firstDraw && this.state.imageWidth !== '' &&
          (drawQuestionCreator || drawStudentResponses)
        ) {
          this.redrawPoints()
        } else if (redrawKey !== previousProps.redrawKey || imagePreviewUrl !== previousProps.imagePreviewUrl ||
          tolerance !== previousProps.tolerance) {
          // used in student responses when deleting a point
          this.redrawPoints()
        }
      }
    }
  }
  // check what the new maximum allowed size should be on each resizing
  updateMaxSize = () => {
    this.containerWidth = document.getElementById('regionDivContainer_' + this.props.uniqueId).offsetWidth
    let maxWidthAllowed = this.maxWidthResults
    if (this.props.type === 'questionCreator' || this.props.type === 'hotspotCreator' ||
      this.props.type === 'directionCreator') {
      maxWidthAllowed = this.maxWidthAuthoring
      // measure based on a different container
      this.containerWidth = document.getElementById('imagePreviewDiv').offsetWidth - 265
    } else if (this.props.type === 'response' || this.props.type === 'directionResponse') {
      maxWidthAllowed = this.maxWidthStudent
    }
    let newMaxWidth = Math.min(this.containerWidth, maxWidthAllowed)
    if (newMaxWidth !== this.maxWidth) {
      this.maxWidth = newMaxWidth
      this.resizeImage()
    }
  }
  // determine the width of the shown image based on the default image width
  onImgLoad = ({ target:img }) => {
    if (this.firstImageLoad && img.offsetWidth !== this.state.imageWidth) {
      this.firstImageLoad = false
      this.originalImageWidth = img.offsetWidth
      this.originalImageHeight = img.offsetHeight
      this.resizeImage()
    }
  }

  resizeImage = () => {
    if (!this.firstImageLoad) {
      let imageWidth = this.originalImageWidth
      let imageHeight = this.originalImageHeight
      const imageRatio = imageHeight / imageWidth
      if (this.props.zoomPerc) {
        imageWidth = imageWidth * this.props.zoomPerc
      } else if (imageWidth > this.maxWidth) {
        imageWidth = this.maxWidth
      }
      // keep the height in the same ratio
      imageHeight = Math.round(imageWidth * imageRatio)
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
      if (!this.props.zoomPerc && imageHeight > (vh - 300)) {
        // case where the image is too tall for the screen
        imageHeight = vh - 300
        imageWidth = imageHeight / imageRatio
      }
      this.setState({
        imageHeight,
        imageWidth,
        showImage: true
      }, this.redrawPoints)
    }
  }
  drawArrow = (x1, y1, x2, y2, studentResult) => {
    const headlen = 10
    const angle = Math.atan2(y2 - y1, x2 - x1)
    const drawWidth = 5
    this.ctx.lineWidth = drawWidth
    if (studentResult) {
      this.ctx.lineWidth = 3
    }
    // Below is an adjustment so that the tip of the arrow is at the mouse when the line width>1
    x2 = x2 - (drawWidth) * Math.cos(angle)
    y2 = y2 - (drawWidth) * Math.sin(angle)
    // end adjustment
    // starting path of the arrow from the start square to the end square and drawing the stroke
    this.ctx.beginPath()
    this.ctx.moveTo(x1, y1)
    this.ctx.lineTo(x2, y2)
    this.ctx.stroke()
    // starting a new path from the head of the arrow to one of the sides of the point
    this.ctx.beginPath()
    this.ctx.moveTo(x2, y2)
    this.ctx.lineTo(x2 - headlen * Math.cos(angle - Math.PI / 7), y2 - headlen * Math.sin(angle - Math.PI / 7))

    // path from the side point of the arrow, to the other side point
    this.ctx.lineTo(x2 - headlen * Math.cos(angle + Math.PI / 7), y2 - headlen * Math.sin(angle + Math.PI / 7))

    // path from the side point back to the tip of the arrow, and then again to the opposite side point
    this.ctx.lineTo(x2, y2)
    this.ctx.lineTo(x2 - headlen * Math.cos(angle - Math.PI / 7), y2 - headlen * Math.sin(angle - Math.PI / 7))

    // draws the paths created above
    this.ctx.stroke()
    this.ctx.fill()
  }
  // redrawPoints is called anytime a click is made on the canvas
  // it redraws all the exisiting points including the new/ changed point
  redrawPoints = () => {
    const { imageHeight, imageWidth } = this.state
    const { correctRegions, type, studentResponses, provideAnswer, noInteraction,
      tolerance, questionType, hideFullAnswer } = this.props
    this.firstDraw = false
    this.ctx.clearRect(0, 0, imageWidth, imageHeight)
    this.ctx.strokeStyle = '#0000ff'
    this.ctx.fillStyle = '#0000ff'
    this.ctx.lineWidth = 2
    const drawOffset = 0
    if (type === 'questionCreator' || type === 'correctAnswer' ||
      (type === 'instructorResults' && provideAnswer && !hideFullAnswer)) { // drawing a full region
      for (let i = 0; i < correctRegions.length; i++) {
        let currentRegion = correctRegions[i]
        if (questionType === 'HOTSPOT') {
          // hotspot scenario
          const drawX = this.setDrawLoc(currentRegion[0], 'x')
          const drawY = this.setDrawLoc(currentRegion[1], 'y')
          this.drawHotspot(drawX, drawY, drawOffset, tolerance)
          this.drawPoint(drawX, drawY, drawOffset)
        } else if (currentRegion.length > 1) {
          this.ctx.strokeStyle = '#0000ff'
          this.ctx.fillStyle = '#0000ff'
          if (type === 'correctAnswer' || (type === 'instructorResults' && provideAnswer)) {
            this.ctx.lineWidth = 3
          }
          this.ctx.beginPath()
          const drawX = this.setDrawLoc(currentRegion[0][0], 'x')
          const drawY = this.setDrawLoc(currentRegion[0][1], 'y')
          this.ctx.moveTo(drawX - drawOffset, drawY - drawOffset)
          for (let j = 1; j < currentRegion.length; j++) {
            const drawX2 = this.setDrawLoc(currentRegion[j][0], 'x')
            const drawY2 = this.setDrawLoc(currentRegion[j][1], 'y')
            this.ctx.lineTo(drawX2 - drawOffset, drawY2 - drawOffset)
          }
          this.ctx.lineTo(drawX - drawOffset, drawY - drawOffset)
          this.ctx.stroke()
        }
        // draw all of the points for the instructor authoring
        if (type !== 'correctAnswer' && !provideAnswer) {
          for (let j = 0; j < currentRegion.length; j++) {
            this.ctx.strokeStyle = '#ffffff'
            this.ctx.fillStyle = '#0000ff'
            const drawX = this.setDrawLoc(currentRegion[j][0], 'x')
            const drawY = this.setDrawLoc(currentRegion[j][1], 'y')
            this.drawPoint(drawX, drawY, drawOffset)
          }
        }
      }
    }
    if (type === 'directionCreator' || type === 'directionResponse' || questionType === 'DIRECTION') {
      this.ctx.strokeStyle = '#0000ff'
      this.ctx.fillStyle = '#0000ff'
      let studentResult = false
      if ((type === 'instructorResults' || type === 'correctAnswer') && provideAnswer && !hideFullAnswer) {
        // correct direction arrow
        if (correctRegions.length > 1) {
          const drawX = this.setDrawLoc(correctRegions[0][0], 'x')
          const drawY = this.setDrawLoc(correctRegions[0][1], 'y')
          const drawX2 = this.setDrawLoc(correctRegions[1][0], 'x')
          const drawY2 = this.setDrawLoc(correctRegions[1][1], 'y')
          this.drawArrow(drawX, drawY, drawX2, drawY2)
        }
      }
      let pointsToDraw = (type === 'directionCreator') ? correctRegions : studentResponses

      if (pointsToDraw.length === 1) {
        const drawX = this.setDrawLoc(pointsToDraw[0][0], 'x')
        const drawY = this.setDrawLoc(pointsToDraw[0][1], 'y')
        this.drawPoint(drawX, drawY, drawOffset)
      } else if (pointsToDraw.length > 1) {
        for (let i = 0; i < pointsToDraw.length; i += 2) {
          if (i + 1 < pointsToDraw.length) {
            // every two points combines to be the student lines
            const individualStudentResponse = [pointsToDraw[i], pointsToDraw[i + 1]]
            const drawX = this.setDrawLoc(individualStudentResponse[0][0], 'x')
            const drawY = this.setDrawLoc(individualStudentResponse[0][1], 'y')
            const drawX2 = this.setDrawLoc(individualStudentResponse[1][0], 'x')
            const drawY2 = this.setDrawLoc(individualStudentResponse[1][1], 'y')
            if (type === 'instructorResults' && provideAnswer) {
              studentResult = true
              if (!correctRegions || correctRegions.length < 2 ||
                this.isArrowCorrect(individualStudentResponse, correctRegions, tolerance)
              ) {
                // green for correct
                this.ctx.strokeStyle = '#038238'
                this.ctx.fillStyle = '#038238'
              } else {
                // red for incorrect
                this.ctx.strokeStyle = '#db0021'
                this.ctx.fillStyle = '#db0021'
              }
            }
            this.drawArrow(drawX, drawY, drawX2, drawY2, studentResult)
            if (!noInteraction && (type === 'directionCreator' || type === 'directionResponse')) {
              // draw points to be clear it's movable
              this.drawPoint(drawX, drawY, drawOffset)
            }
          }
        }
      }
    } else if (!(type === 'questionCreator' || type === 'correctAnswer')) { // responses
      this.ctx.strokeStyle = '#ffffff'
      this.ctx.fillStyle = '#76d5d4'
      // draw only points
      for (let i = 0; i < studentResponses.length; i++) {
        this.ctx.beginPath()
        if (type === 'instructorResults' && provideAnswer) {
          if (this.isPointCorrect(studentResponses[i], correctRegions, tolerance)) {
            // green for correct
            this.ctx.fillStyle = '#038238'
          } else {
            // red for incorrect
            this.ctx.fillStyle = '#db0021'
          }
        }
        const drawX = this.setDrawLoc(studentResponses[i][0], 'x')
        const drawY = this.setDrawLoc(studentResponses[i][1], 'y')
        if (type === 'hotspotCreator') {
          this.drawHotspot(drawX, drawY, drawOffset, tolerance)
        }
        this.drawPoint(drawX, drawY, drawOffset)
      }
    }
  }
  drawHotspot = (drawX, drawY, drawOffset, tolerance) => {
    this.ctx.beginPath()
    this.ctx.fillStyle = 'rgba(0, 0, 255, 0.2)'
    this.ctx.strokeStyle = '#0000ff'
    // below is the tolerance bubble
    let imageWidth = this.originalImageWidth
    if (this.props.zoomPerc) {
      imageWidth = this.originalImageWidth * this.props.zoomPerc
    } else if (this.originalImageWidth > this.maxWidth) {
      imageWidth = this.state.imageWidth
    }
    let toleranceRadius = imageWidth * (tolerance / 750) // 750 is arbitrary found through trial and error
    if (toleranceRadius < 0) {
      // prevent a bug with negative radius
      toleranceRadius = 0.1
    }
    this.ctx.arc(drawX - drawOffset, drawY - drawOffset, toleranceRadius, 0, 2 * Math.PI, true)
    this.ctx.stroke()
    this.ctx.fill()
    this.ctx.beginPath()
    this.ctx.fillStyle = '#0000ff'
  }

  drawPoint = (drawX, drawY, drawOffset) => {
    this.ctx.beginPath()
    this.ctx.arc(drawX - drawOffset, drawY - drawOffset, 5, 0, 2 * Math.PI, true)
    this.ctx.stroke()
    this.ctx.fill()
  }
  preventMobileScroll = (e) => {
    e.preventDefault()
  }
  // used to disable the default scrolling when in drag mode for moble devices
  mobileScrolling = (enabled) => {
    if (enabled) {
      window.removeEventListener('touchstart', this.preventMobileScroll)
      window.removeEventListener('touchmove', this.preventMobileScroll)
    } else {
      window.addEventListener('touchstart', this.preventMobileScroll, { passive: false })
      window.removeEventListener('touchmove', this.preventMobileScroll)
    }
  }
  startPointEdit = (event) => {
    if (event.type === 'touchstart') {
      this.mobileScenario = true
    }
    this.mobileScrolling(false) // prevent mobile scrolling while clicking on the canvas
    if (!this.mobileScenario || event.type === 'touchstart') {
      // don't do if click event on a mobile device
      const { correctRegions, moveChangeRegionPoint, type, studentResponses, numberRegions,
        questionType } = this.props
      this.currentlyMovingPoint = true
      this.clickStartTime = new Date().getTime()
      const convertedPoints = this.convertPointToPercentageOfTotal(event)
      const x = convertedPoints[0]
      const y = convertedPoints[1]
      this.currentPointIndex = 0
      let addPointBetweenLines = false
      this.addingNewPoint = true
      if (type === 'questionCreator' || type === 'correctAnswer') { // matrix of points
        if (correctRegions && this.currentRegionNumber >= correctRegions.length) {
          // prevent bug on question creator
          this.currentRegionNumber = correctRegions.length - 1
        }
        this.currentPointIndex = correctRegions[this.currentRegionNumber].length
        for (let i = 0; i < correctRegions.length; i++) {
          for (let j = 0; j < correctRegions[i].length; j++) {
            const savedX = correctRegions[i][j][0]
            const savedY = correctRegions[i][j][1]
            if (this.checkIfClickIsClose(x, y, savedX, savedY, 10)) {
              this.currentPointIndex = j
              this.currentRegionNumber = i
              this.originalPointLoc = [savedX, savedY]
              this.addingNewPoint = false
              break
            }
          }
        }
        if (this.currentPointIndex === correctRegions[this.currentRegionNumber].length &&
            this.currentRegionNumber === (correctRegions.length - 1)
        ) {
          // no existing point was found within the range.  Check to see if a line is in the range
          for (let i = 0; i < correctRegions.length; i++) {
            if (correctRegions[i].length > 0) {
              let lastSavedX = correctRegions[i][0][0]
              let lastSavedY = correctRegions[i][0][1]
              for (let j = 1; j < correctRegions[i].length; j++) {
                const savedX = correctRegions[i][j][0]
                const savedY = correctRegions[i][j][1]
                if (this.checkIfClickIsCloseToLine(x, y, savedX, savedY, lastSavedX, lastSavedY)) {
                  this.currentPointIndex = j
                  this.currentRegionNumber = i
                  this.originalPointLoc = [savedX, savedY]
                  addPointBetweenLines = true
                  break
                }
                lastSavedX = savedX
                lastSavedY = savedY
              }
            }
          }
        }
      } else { // single array of points
        this.currentPointIndex = studentResponses.length
        if (this.currentPointIndex === 1 && studentResponses[0] && studentResponses[0].length === 0) {
          this.currentPointIndex = 0
        }
        let numRegions = numberRegions
        if (questionType === 'DIRECTION') {
          // direction question type allows allows two points to draw arrow
          numRegions = 2
        }
        this.currentRegionNumber = numRegions
          ? Math.min(studentResponses.length, numRegions - 1) : this.currentPointIndex
        if (type === 'directionCreator') {
          this.currentRegionNumber = Math.min(correctRegions.length, 1)
          if (correctRegions[0] && correctRegions[0].length === 0) {
            this.currentRegionNumber = 0
          }
        }
        // search to see if this is editing an existing point
        for (let i = 0; i < studentResponses.length; i++) {
          const savedX = studentResponses[i][0]
          const savedY = studentResponses[i][1]
          const arrowHead = ((type === 'directionCreator' || type === 'directionResponse') && i === 1)
          if (this.checkIfClickIsClose(x, y, savedX, savedY, 10) ||
            (arrowHead && this.checkIfClickIsCloseToArrowHead(
              x, y, studentResponses[0][0], studentResponses[0][1], savedX, savedY, 10
            ))
          ) {
            this.currentRegionNumber = i
            this.originalPointLoc = [x, y]
            break
          }
        }
      }
      const notLastDirectionPoint = !((type === 'directionCreator' && correctRegions.length === 2) ||
        (type === 'directionResponse' && studentResponses.length === 2))
      if ((type !== 'response' && notLastDirectionPoint) ||
        this.originalPointLoc != null || studentResponses.length < numberRegions) {
        // only change if not moving the last point for the student response
        if (moveChangeRegionPoint) {
          moveChangeRegionPoint(this.currentRegionNumber, this.currentPointIndex, x, y, addPointBetweenLines, type)
          this.redrawPoints()
        }
      } else {
        this.currentlyMovingPoint = false
      }
    }
  }
  // x= x-coordinate of student answer, y = y-coordinate of student answer
  // hotspotX = x-coordinate of hotspot, hotspotY = y-coordinate of hotspot
  isPointInHotspot = (x, y, hotspotX, hotspotY, regionTolerance) => {
    const xPoint = this.setDrawLoc(x, 'x')
    const yPoint = this.setDrawLoc(y, 'y')
    const xHotspot = this.setDrawLoc(hotspotX, 'x')
    const yHotspot = this.setDrawLoc(hotspotY, 'y')
    const distanceToPoint = this.distanceBetweenTwoPoints(xPoint, yPoint, xHotspot, yHotspot)
    // 7.5 since there is 750 in the tolerance drawing, and the scale is 0 - 100
    // below is the tolerance bubble
    let imageWidth = this.originalImageWidth
    if (this.props.zoomPerc) {
      imageWidth = this.originalImageWidth * this.props.zoomPerc
    } else if (this.originalImageWidth > this.maxWidth) {
      imageWidth = this.state.imageWidth
    }
    const toleranceRadius = imageWidth * (regionTolerance / 750) // 750 is arbitrary found through trial and error
    if (distanceToPoint <= toleranceRadius) { // point is within tolerance radius
      return true
    } else {
      return false
    }
  }

  // studentArrow and correctArrow are arrays with two values: [x-coordinate, y-coordinate]
  isArrowCorrect = (studentArrow, correctArrow, regionTolerance) => {
    let studentAngle = this.getAngle(studentArrow)
    let correctAngle = this.getAngle(correctArrow)
    let angleDifference = Math.abs(correctAngle - studentAngle)
    if (angleDifference > 180) {
      // possible that one angle is close to 360 and the other is close to 0
      // convert the lower angle to be closer to the 'near 360 degrees' point to get a true measure of the difference
      if (correctAngle < 180) {
        correctAngle += 360
      } else if (studentAngle < 180) {
        studentAngle += 360
      }
      angleDifference = Math.abs(correctAngle - studentAngle)
    }
    if (angleDifference <= regionTolerance) {
      return true
    } else {
      return false
    }
  }

  getAngle = (points) => {
    // angles will go from 0 to 360 degrees
    let angle = Math.atan2(points[1][1] - points[0][1], points[1][0] - points[0][0]) * 180 / Math.PI
    if (angle < 0) { // adjust negative angles
      angle = 360 + angle
    }
    return angle
  }

  // loop through all of the correct regions to see if the user point is in at least one of them
  isPointCorrect = (userPoint, correctRegions, tolerance) => {
    for (let i = 0; i < correctRegions.length; i++) {
      if (this.props.questionType === 'HOTSPOT') {
        if (this.isPointInHotspot(userPoint[0], userPoint[1], correctRegions[i][0], correctRegions[i][1], tolerance)) {
          return true
        }
      } else if (this.isPointInside(userPoint, correctRegions[i])) {
        return true
      }
    }
    return false
  }

  // true when you can move a prior point
  checkIfClickIsClose = (x, y, savedX, savedY, bufferRange) => {
    if (this.mobileScenario) {
      // bigger range for mobile devices
      bufferRange = bufferRange * 2
    }
    const tolerance = this.convertToPercentTolerance(bufferRange)
    if (x > (savedX - tolerance[0]) && x < (savedX + tolerance[0]) &&
        y > (savedY - tolerance[1]) && y < (savedY + tolerance[1])
    ) {
      return true
    } else {
      return false
    }
  }
  // true when click is inside the arrow head for direction questions
  checkIfClickIsCloseToArrowHead = (x, y, x1, y1, x2, y2) => {
    const headlen = 10
    const angle = Math.atan2(y2 - y1, x2 - x1)
    const arrowX1 = x2
    const arrowY1 = y2
    const arrowX2 = x2 - headlen * Math.cos(angle + Math.PI / 7)
    const arrowY2 = y2 - headlen * Math.sin(angle + Math.PI / 7)
    const arrowX3 = x2 - headlen * Math.cos(angle - Math.PI / 7)
    const arrowY3 = y2 - headlen * Math.sin(angle - Math.PI / 7)
    const areaA = this.triangleArea(arrowX1, arrowY1, arrowX2, arrowY2, arrowX3, arrowY3)
    const areaA1 = this.triangleArea(x, y, arrowX2, arrowY2, arrowX3, arrowY3)
    const areaA2 = this.triangleArea(arrowX1, arrowY1, x, y, arrowX3, arrowY3)
    const areaA3 = this.triangleArea(arrowX1, arrowY1, arrowX2, arrowY2, x, y)
    const areaDiff = Math.abs(areaA - (areaA1 + areaA2 + areaA3))
    const diffAllowed = this.mobileScenario ? 14 : 8 // greater area allowed for mobile
    if (areaDiff < diffAllowed) {
      return true
    } else {
      return false
    }
  }

  triangleArea = (x1, y1, x2, y2, x3, y3) => {
    // find length of sides of triangle
    const side1 = Math.sqrt(Math.pow((x2 - x1), 2) + Math.pow((y2 - y1), 2), 2)
    const side2 = Math.sqrt(Math.pow((x3 - x2), 2) + Math.pow((y3 - y2), 2), 2)
    const side3 = Math.sqrt(Math.pow((x1 - x3), 2) + Math.pow((y1 - y3), 2), 2)
    const s = (side1 + side2 + side3) / 2
    return Math.sqrt(s * (s - side1) * (s - side2) * (s - side3)) * 0.5
  }

  // x and y tolerance seperately
  convertToPercentTolerance = (pixelBuffer) => {
    return [(pixelBuffer / this.state.imageWidth) * 100, (pixelBuffer / this.state.imageHeight) * 100]
  }

  // true when you add a point between two lines
  checkIfClickIsCloseToLine = (x, y, savedX, savedY, lastSavedX, lastSavedY) => {
    const distancePoints = this.distanceBetweenTwoPoints(savedX, savedY, lastSavedX, lastSavedY)
    const d1 = this.distanceBetweenTwoPoints(x, y, lastSavedX, lastSavedY)
    const d2 = this.distanceBetweenTwoPoints(savedX, savedY, x, y)
    const distanceDifference = Math.abs(distancePoints - (d1 + d2))
    const rangeDiff = this.mobileScenario ? 0.4 : 0.2
    // larger range for mobile devices
    if (Math.abs(distanceDifference) < rangeDiff) {
      return true
    } else {
      return false
    }
  }

  distanceBetweenTwoPoints = (x1, y1, x2, y2) => {
    const a = x1 - x2
    const b = y1 - y2
    return Math.sqrt((a * a) + (b * b))
  }

  // method will move the current point based on mouse movement
  movePointInRegion = (event) => {
    // only draw if the mouse is currently down and moving
    if (this.currentlyMovingPoint && (!this.mobileScenario || event.type === 'touchmove')) {
      // don't do if click event on a mobile device
      const convertedPoints = this.convertPointToPercentageOfTotal(event)
      const x = convertedPoints[0]
      const y = convertedPoints[1]
      if (this.props.moveChangeRegionPoint != null) {
        this.props.moveChangeRegionPoint(this.currentRegionNumber, this.currentPointIndex, x, y, false, this.props.type)
      }
      if (this.originalPointLoc != null) {
        // check if point has moved away from original location
        const tolerance = this.convertToPercentTolerance(5)
        if ((x - this.originalPointLoc[0]) >= tolerance[0] || (y - this.originalPointLoc[1]) >= tolerance[1]) {
          this.originalPointLoc = null // reset now that point has moved significantly
        }
      }
      this.redrawPoints()
    }
  }

  checkDeleteResponse = (event) => {
    this.mobileScrolling(true) // allow for scrolling on mobile now
    const currentTime = new Date().getTime()
    // has a check to make sure click events aren't happening on mobile devices
    if ((!this.mobileScenario || event.type === 'touchend') &&
      this.originalPointLoc != null && (currentTime - this.clickStartTime) < 200
    ) {
      const convertedPoints = this.convertPointToPercentageOfTotal(event)
      const x = convertedPoints[0]
      const y = convertedPoints[1]
      // tolerance of 5 pixels on x or y for desktop, 12 for mobile devices
      const tolerancePixels = this.mobileScenario ? 12 : 5
      const tolerance = this.convertToPercentTolerance(tolerancePixels)
      if (Math.abs(x - this.originalPointLoc[0]) < tolerance[0] &&
        Math.abs(y - this.originalPointLoc[1]) < tolerance[1]) {
        this.props.deletePoint(this.currentRegionNumber, this.currentPointIndex, this.props.type)
      }
      this.originalPointLoc = null // reset now click is finished
    }
  }

  // needed because the coordinates are stored on a 0 - 100 scale for consistency
  convertPointToPercentageOfTotal = (event) => {
    const rect = this.canvas.getBoundingClientRect()
    let clientX = event.clientX
    let clientY = event.clientY
    if ((event.type === 'touchstart' || event.type === 'touchmove') && event.touches[0]) {
      // touch event
      clientX = event.touches[0].clientX
      clientY = event.touches[0].clientY
    } else if (event.type === 'touchend' && event.changedTouches[0]) {
      clientX = event.changedTouches[0].clientX
      clientY = event.changedTouches[0].clientY
    }
    const x = ((clientX - rect.left) / this.state.imageWidth) * 100
    const y = ((clientY - rect.top) / this.state.imageHeight) * 100
    return [x, y]
  }

  // adjust drawing location to be at the center of where the user clicks
  // NOTE: coordinates are saved on a 0 - 100 scale.  100 = 100% of the width or height. 0 = 0% of the width or height
  setDrawLoc = (locationT, axisType) => {
    // line below will transform the points to the current image size
    if (locationT > 100) {
      locationT = 100
    }
    if (locationT < -50) {
      // specific case where the student response is changed by instructor
      // temporary fix to prevent the arbitrary instructor response from showing
      return locationT
    }
    const pixelBuffer = 5
    let location = (locationT / 100) * this.state.imageWidth
    if (axisType === 'y') {
      location = (locationT / 100) * this.state.imageHeight
      if (location >= (this.state.imageHeight - pixelBuffer)) {
        location = this.state.imageHeight - pixelBuffer - 0.5
      }
    } else if (location >= (this.state.imageWidth - pixelBuffer)) {
      location = this.state.imageWidth - pixelBuffer - 0.5
    }
    if (location <= pixelBuffer + 1) {
      location = pixelBuffer + 1.5
    }
    return location
  }
  addRegionLocal = () => {
    const { correctRegions, addRegion } = this.props
    // don't add another region if the current region is empty
    if (correctRegions[this.currentRegionNumber].length > 1) {
      this.currentRegionNumber++
      addRegion(this.currentRegionNumber)
    }
  }
  // clear all drawings from the graph
  clearRegionsLocal = () => {
    const { imageHeight, imageWidth } = this.state
    this.currentRegionNumber = 0
    this.ctx.clearRect(0, 0, imageWidth, imageHeight)
    this.props.clearRegions()
  }
  checkNewTolerance = (event) => {
    let tolerance = Number(event.target.value)
    if (tolerance >= 0 && tolerance <= 50) {
      this.props.setRegionTolerance(tolerance)
    } else {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  isPointInside = (userPoint, oneCorrectRegion) => {
    // ray-casting algorithm
    const x = userPoint[0]
    const y = userPoint[1]
    let inside = false
    for (let i = 0, j = oneCorrectRegion.length - 1; i < oneCorrectRegion.length; j = i++) {
      const xi = oneCorrectRegion[i][0]
      const yi = oneCorrectRegion[i][1]
      const xj = oneCorrectRegion[j][0]
      const yj = oneCorrectRegion[j][1]
      const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi)
      if (intersect) {
        inside = !inside
      }
    }
    return inside
  }

  render () {
    const { imageHeight, imageWidth, showImage } = this.state
    const { imagePreviewUrl, allRegionsRequired, handleAllRegionsChange, tolerance, setRegionTolerance,
      correctRegions, type, showRegionError, noInteraction, alternateText, uniqueId, numberRegions,
      studentResponses
    } = this.props
    let $imagePreview = null
    let canvasStyle = {}
    let containerStyle = { // prevent the image from flashing before we have sized it
      display: 'hidden'
    }
    let buttonContainerStyle = {}
    let canvasWidth = 0
    let canvasHeight = 0
    const altText = alternateText != null && alternateText !== '' ? alternateText : 'Image preview'
    if (imagePreviewUrl) {
      let imageClassName = ''
      if (!showImage) {
        // first time display will be off screen until we determine the original sizing
        imageClassName = styles.offScreenImage
      }
      if (imageHeight !== '') {
        canvasWidth = imageWidth
        canvasHeight = imageHeight
        // below will put the canvas on top of the image
        canvasStyle = {
          top: '-' + (imageHeight + 5) + 'px'
        }
        containerStyle = {
          height: (imageHeight) + 'px',
          width: canvasWidth + 'px'
        }
        buttonContainerStyle = {
          width: canvasWidth + 'px'
        }
      }
      if (showRegionError) {
        // slight top and left adjustment for a 1px border instead of the default 2px border
        canvasStyle = {
          top: '-' + (imageHeight + 3) + 'px',
          left: '-1px',
          border: 'solid 1px #db0021'
        }
      }
      $imagePreview = (
        <img
          alt={altText}
          id={'imagePreview' + type}
          width={imageWidth}
          height={imageHeight}
          className={imageClassName}
          src={imagePreviewUrl}
          onLoad={this.onImgLoad}
        />
      )
    }
    let drawingShowing = false
    let showAllRegionsCheck = false
    let addAnotherRegionShowing = false
    let cursorClass = styles.crosshair
    if ((type === 'response' && studentResponses.length >= numberRegions) ||
      (type === 'directionResponse' && studentResponses.length === 2) ||
      (type === 'directionCreator' && correctRegions.length === 2)
    ) {
      // take away crosshair when there are no more points to add
      cursorClass = ''
    }
    let drawingContainerClass = styles.drawingContainer + ' ' + cursorClass
    let canvasMouseDownFunc = this.startPointEdit
    let canvasMouseMoveFunc = this.movePointInRegion
    let canvasMouseUpFunc = this.checkDeleteResponse
    if (
      (type === 'questionCreator' || type === 'hotspotCreator' || type === 'directionCreator') &&
      correctRegions != null
    ) {
      if (correctRegions.length > 1) {
        drawingShowing = true
        if (uniqueId !== 'questionAuthoringEnlarged') {
          // check to see if we have more than 1 drawn region
          if (type === 'questionCreator') {
            // region question type
            for (let i = 1; i < correctRegions.length; i++) {
              if (correctRegions[i].length > 2) {
                showAllRegionsCheck = true
                break
              }
            }
          } else if (type === 'hotspotCreator') {
            // hotspot
            showAllRegionsCheck = true
          }
        }
      }
      if (!drawingShowing && correctRegions[0] instanceof Array && correctRegions[0].length > 0) {
        drawingShowing = true
      }

      if (drawingShowing) {
        // only show the add another region option if the most recent region has at least 3 points
        if (correctRegions[correctRegions.length - 1].length > 2) {
          addAnotherRegionShowing = true
        }
      }
    } else if (type === 'response') {
      drawingContainerClass += ' ' + styles.centeredDiv
    } else if (type === 'instructorResults') {
      canvasMouseDownFunc = null
      canvasMouseMoveFunc = null
      canvasMouseUpFunc = null
      drawingContainerClass = styles.drawingContainer
    }
    if (noInteraction) {
      canvasMouseDownFunc = null
      canvasMouseMoveFunc = null
      canvasMouseUpFunc = null
    }
    const allRegionsLabel = type === 'hotspotCreator'
      ? 'All hotspots required for correct answer' : 'All regions required for correct answer'
    const regionErrorMessage = type === 'directionCreator'
      ? 'Incomplete direction arrow.  Add at least two points to create a direction arrow.'
      : 'Incomplete region.  Outline a region before saving.'
    return (
      <div id={'regionDivContainer_' + uniqueId}>
        {imagePreviewUrl &&
          <div className='regionContainerDiv'>
            <div
              className={drawingContainerClass}
              style={containerStyle}
            >
              {$imagePreview}
              <canvas
                id='drawingCanvas'
                ref='drawingCanvas'
                width={canvasWidth}
                height={canvasHeight}
                onMouseDown={canvasMouseDownFunc}
                onTouchStart={canvasMouseDownFunc}
                onMouseMove={canvasMouseMoveFunc}
                onTouchMove={canvasMouseMoveFunc}
                onMouseUp={canvasMouseUpFunc}
                onTouchEnd={canvasMouseUpFunc}
                style={canvasStyle}
              />
            </div>
          </div>
        }
        {(drawingShowing || showRegionError) &&
          <div id='drawingOptionsDiv' className={'flex ' + styles.drawingOptionsDiv} style={buttonContainerStyle}>
            {showRegionError ? (
              <span className='region_error_message'>
                <svg version='1.1' xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink' role='img'
                  focusable='false'
                  aria-labelledby='warning-sm-18-icon'
                  className='pe-icon--warning-sm-18'>
                  <title id='warning-sm-18-icon'>Error message</title>
                  <use xlinkHref='#warning-sm-18' />
                </svg>
                <label>{regionErrorMessage}</label>
              </span>
            ) : (addAnotherRegionShowing &&
              <button
                id='addAnotherRegion'
                className='addRegionButton'
                aria-label='Add another region'
                onClick={this.addRegionLocal}
              >
                Add another region
              </button>
            )}
            {(type === 'hotspotCreator' || type === 'directionCreator') &&
              <div className='toleranceSlider'>
                <label htmlFor='regionTolerence'>Response tolerance</label>
                <div className='inputFlex'>
                  <input
                    type='range'
                    min='1'
                    max='50'
                    value={tolerance}
                    className='slider'
                    id='regionTolerence'
                    onChange={(event) => setRegionTolerance(Number(event.target.value))}
                  />
                  {type === 'directionCreator' &&
                    <React.Fragment>
                      <input
                        value={tolerance}
                        id='regionTolerenceInput'
                        type='number'
                        className='pe-textInput--basic toleranceInput'
                        aria-label='Enter the degree tolerance for the direction answers'
                        onChange={this.checkNewTolerance}
                    />
                      <label>
                        degrees
                      </label>
                    </React.Fragment>
                  }
                </div>
              </div>
            }
            {drawingShowing &&
              <button
                id='clearButton'
                className='clearRegionButton'
                aria-label='Clear all regions'
                onClick={this.clearRegionsLocal}
              >
                Clear
              </button>
            }
          </div>
        }
        {showAllRegionsCheck &&
          <div className='pe-checkbox mt-24'>
            <input
              id='allRegionsRequiredCheck'
              type='checkbox'
              name='allRegionsRequiredCheck'
              checked={allRegionsRequired}
              onChange={handleAllRegionsChange}
            />
            <label htmlFor='allRegionsRequiredCheck'>{allRegionsLabel}</label>
            <span>
              <svg aria-hidden='true' focusable='false' className='pe-icon--correct-18'>
                <use xlinkHref='#correct-18' />
              </svg>
            </span>
          </div>
        }

      </div>

    )
  }
}

export default RegionDrawing
