/* eslint-disable @typescript-eslint/no-this-alias */
import styles from "./matheditor.module.scss";
import React from "react";
import LoadingIndicator from "./../LoadingIndicator";
import MathEditor from "./MathEditor";
import { Editor } from "@tinymce/tinymce-react";
import { loadScript } from "../_util/common";
import PropTypes from "prop-types";
const $ = window.jQuery;
window.$ = window.jQuery;

class TextEditor extends React.Component {
  constructor() {
    super();
    this.state = {
      mathdata: "",
      editorIsOpen: false,
      content: undefined,
      contentId: undefined,
      editorLoader: true,
    };
    this.editor = undefined;
    this.handleEditorLoad = this.handleEditorLoad.bind(this);
  }

  setEditorOpen = (value, contentId = undefined, content = "") => {
    let math = "";
    if (content) {
      math = JSON.parse(content.getAttribute("mathdata"));
    }

    this.setState({
      editorIsOpen: value,
      content: math,
      contentId,
    });
  };

  handleEditorChange = (content) => {
    this.props.onChange(content);
  };

  setEditorInstance = () => {
    if (typeof this.props.setEditorInstance === "function") {
      this.props.setEditorInstance(this.editor);
    }
  };

  getExpressionsByID(html) {
    let expressions = {};
    const jhtml = $(`<div>${html}</div>`);
    jhtml.find(`.pmml`).each((idx, elem) => {
      const pmmlClsName = window
        .$(elem)
        .attr("class")
        .split(/\s+/)
        .find((clstr) => clstr.match(/pmml-key/));
      expressions[pmmlClsName] = elem.innerHTML;
    });
    return expressions;
  }

  replaceExpressions(expressions, html) {
    const jhtml = $(`<div>${html}</div>`);
    jhtml.find(`.pmml`).each((idx, elem) => {
      const pmmlClsName = window
        .$(elem)
        .attr("class")
        .split(/\s+/)
        .find((clstr) => clstr.match(/pmml-key/));
      if (expressions[pmmlClsName]) {
        window.$(elem).html(expressions[pmmlClsName]);
      }
    });
    return jhtml.html();
  }

  disableContentEditable = (str) => {
    return str
      .replace(
        new RegExp(
          '<div class="pmml mceNonEditable ppbMathElement pmml-key-',
          "g"
        ),
        '<div contenteditable="false" class="pmml mceNonEditable ppbMathElement pmml-key-'
      )
      .replace(
        new RegExp('class="mce-item pmml-child mceNonEditable pmml-key-', "g"),
        'contenteditable="false" class="mce-item pmml-child mceNonEditable pmml-key-'
      );
  };

  setMathContent = (pmml, cmml, svg, mlId, oldMlId) => {
    // cmml = cmml.replace(/<\/body>/gi,"").replace(/<body>/gi,"")
    let mathdata = JSON.stringify(cmml);
    //Fix Mathdata missing for <input/> tag
    mathdata = mathdata.replace(/<input\s*\/>/g, "");

    const finalPMML = `<div class="pmml mceNonEditable ppbMathElement pmml-key-${mlId}">
            <div onclick="window.parent.$.data(parent.document.body, 'modify','${mlId}');
            window.parent.toggleMathEditor(true, '${mlId}', this);"
            ontouchstart="window.parent.$.data(parent.document.body, 'modify','${mlId}'); window.parent.touchToggleMathEditor(event,true, '${mlId}', this);"
            class="mce-item pmml-child mceNonEditable pmml-key-${mlId}" mathdata='${mathdata}'  pmmldata='${JSON.stringify(
      svg
    )}'
    >${svg}</div>
            </div> `;

    const fhtml = finalPMML;
    if (oldMlId) {
      window
        .$(this.editor.iframeElement)
        .contents()
        .find("body")
        .find(`.pmml.pmml-key-${oldMlId}`)
        .replaceWith(this.disableContentEditable(fhtml));
      this.editor.setDirty(true);
      this.editor.fire("change");
      if (this.editor.getDoc().defaultView.MathJax.typeset) {
        this.editor.getDoc().defaultView.MathJax.typeset();
      }
      this.editor.execCommand("mceCleanup", false, fhtml);
    } else {
      this.editor.execCommand("mceInsertContent", false, fhtml);
      window
        .$(this.editor.iframeElement)
        .contents()
        .find("body")
        .find(`.pmml.pmml-key-${mlId}`)
        .replaceWith(this.disableContentEditable(fhtml));
      this.editor.execCommand("mceCleanup", false, fhtml);
    }
  };

  componentDidMount() {
    window.toggleMathEditor = this.setEditorOpen;
    window.touchToggleMathEditor = (e, value, contentId, content) => {
      e.preventDefault();
      this.setEditorOpen(value, contentId, content);
    };

    if (this.props.loader) {
      this.setState({
        editorLoader: false,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialContent !== prevProps.initialContent) {
      this.editor.setContent(this.props.initialContent);
    }
  }

  getMathMl = (content) => {
    let parser = new DOMParser();
    var contentElement = document.createElement("div");
    //remove no script
    content = content.replace(/<noscript[\s\S]*?>[\s\S]*?<\/noscript>/gi, "");
    contentElement.innerHTML = content;
    var header = document.createElement("head");
    if (contentElement.getElementsByTagName("head").length === 0) {
      contentElement.prepend(header);
    }
    contentElement.querySelectorAll("link").forEach((e) => {
      contentElement.getElementsByTagName("head")[0].appendChild(e);
    });
    let parsedHtml = parser.parseFromString(
      contentElement.innerHTML,
      "text/html"
    );
    const mathElements = parsedHtml.querySelectorAll(".pmml-child");
    mathElements.forEach(function (element) {
      const originalMathContent = JSON.parse(element.getAttribute("pmmldata"));
      const mathElement = parsedHtml.querySelector(
        `.pmml-child.${element.classList[3]}`
      );
      mathElement.innerHTML = originalMathContent;
    });
    return `${parsedHtml.head.outerHTML}${parsedHtml.body.outerHTML}`;
  };

  onBeforeSetContent = (content) => {
    content.content = this.getMathMl(content.content);
  };

  handleEditorLoad() {
    loadScript(
      "Jquery lib",
      "https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js",
      () => {
        console.log("JQuery loaded");
      }
    );
    loadScript(
      "Mathjax lib",
      "https://learningapps-ui-ppe.pearson.com/b0/qa-int/lass-ui-cdn-libs/latest/matheditor/js/mathjax.js",
      () => {
        this.setState({
          editorLoader: true,
        });
      }
    );
    console.log("Mathjax Lib loaded");
  }

  customMediaResolver = (data, resolve /*, reject*/) => {
    let embedHtml = "";
    let iFrame_1 = '<iframe src="https://www.youtube.com/embed/';
    let iFrame_2 = '" width="400" height="200" ></iframe>';
    if (data.url.indexOf(data.url) !== -1) {
      if (data.url.includes("https://youtu.be")) {
        embedHtml = iFrame_1 + data.url.split("youtu.be/", 2).pop() + iFrame_2;
        resolve({ html: embedHtml });
      } else if (data.url.includes("https://www.youtube")) {
        embedHtml =
          iFrame_1 +
          data.url.split("youtube.com/watch?v=", 2).pop().replace("&", "?") +
          iFrame_2;
        resolve({ html: embedHtml });
      } else {
        embedHtml = '<iframe src="' + data.url + iFrame_2;
        resolve({ html: embedHtml });
      }
    } else {
      resolve({ html: "" });
    }
  };

  render() {
    const { editorIsOpen, content, contentId, editorLoader } = this.state;
    const that = this;
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingIndicator
            strokeWidth="5"
            color="#047a9c"
            width="50px"
            height="50px"
            dashDuration="2s"
            rotateDuration="2s"
            isHidden={editorLoader}
            isLoading
          />
        </div>
        <div
          className={styles.matheditor_container}
          style={!editorLoader ? { display: "none" } : {}}
        >
          <div id="mathjax_rendering_sandbox" style={{ display: "none" }}></div>
          {this.props.mathEditor && (
            <MathEditor
              isOpen={editorIsOpen}
              content={content || ""}
              contentId={contentId}
              closeEditor={() => this.setEditorOpen(false)}
              setContent={(pmml, cmml, svg, mlId, oldMlId, mml) =>
                this.setMathContent(pmml, cmml, svg, mlId, oldMlId, mml)
              }
            />
          )}
          <Editor
            // apiKey={this.props.tinymceApiKey}
            tinymceScriptSrc="https://learningapps-ui-ppe.pearson.com/b0/qa-int/lass-ui-cdn-libs/latest/tinymce/v6.7.1/js/tinymce/tinymce.min.js"
            initialValue={this.props.initialContent}
            onInit={this.handleEditorLoad}
            init={{
              automatic_uploads: false,
              convert_urls: false,
              ie7_compat: false,
              force_br_newlines: false,
              link_default_target: "_blank",
              link_context_toolbar: true,
              link_assume_external_targets: true,
              link_default_protocol: "https",
              link_title: false,
              //   fullpage_hide_in_source_view: false,
              custom_elements:
                "math,maction,maligngroup,malignmark,menclose,merror,mfenced,mfrac," +
                "mglyph,mi,mlabeledtr,mlongdiv,mmultiscripts,mn,mo,mover,mpadded,mphantom,mroot," +
                "mrow,ms,mscarries,mscarry,msgroup,msline,mspace,msqrt,msrow,mstack,mstyle,msub," +
                "msup,msubsup,mtable,mtd,mtext,mtr,munder,munderover,svg,mjx-container,defs,path,g,use,mjx-assistive-mml",
              extended_valid_elements:
                "span[*],line[x1|y1|x2|y2],g[stroke-width|stroke|fill|transform],svg[style|role]" +
                ",div[id|onclick|style|ontouchstart|class|contenteditable|xmlns|mathdata|pmmldata],path[id|d],input[accept|alt|checked|disabled|maxlength|name|readonly|size|src|type|value|onclick|type]" +
                ",audio[id],source[src|type],img[longdesc|usemap|src|border|alt=|title|hspace|vspace|width|height|align|onclick|class|data-mce-resize|data-mce-placeholder|style]" +
                ",mo[stretchy],mspace[width|linebreak],mtd[columnalign],mpadded[width],mfenced[open|close],menclose[notation],em[*],script[*],a[*],li[*],ul[*],link[*],head[*],title[*]",
              // content_style:
              //   "mjx-container { display:block; line-height: 1.4 !important }",
              content_css:
                "https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap, https://fonts.googleapis.com/css2?family=UnifrakturMaguntia&display=swap, https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap",
              plugins:
                "preview searchreplace autolink autosave save directionality code visualblocks " +
                "visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking " +
                "anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons",
              menubar: this.props.showMenu,
              menu: {
                file: { title: "File", items: "preview | print " },
                edit: {
                  title: "Edit",
                  items:
                    "undo redo | cut copy paste pastetext | selectall | searchreplace",
                },
                view: {
                  title: "View",
                  items: "code | spellchecker | preview fullscreen",
                },
                insert: {
                  title: "Insert",
                  items: this.props.insertItems,
                },
                format: {
                  title: "Format",
                  items:
                    "bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat",
                },
                tools: {
                  title: "Tools",
                  items: "spellchecker | code wordcount",
                },
                table: {
                  title: "Table",
                  items:
                    "inserttable | cell row column | tableprops deletetable",
                },
              },
              statusbar: this.props.statusbar,
              media_poster: false,
              media_alt_source: false,
              media_url_resolver: this.customMediaResolver,
              toolbar: this.props.toolbar,
              toolbar_sticky: false,
              height: this.props.height,
              quickbars_selection_toolbar: this.props.quickbarsSelectionToolbar,
              quickbars_insert_toolbar: false,
              noneditable_class: "mceNonEditable",
              toolbar_mode: "sliding",

              image_title: false,
              image_description: false,
              image_dimensions: false,
              images_upload_handler: this.props.imageUploadHandler,
              images_file_types: "jpg,png,jpeg",
              document_base_url: this.props.baseUrl,
              mobile: {
                toolbar_mode: "sliding",
                menubar: "file edit view insert format tools table",
              },
              auto_focus: this.props.autoFocus,
              setup: function (editor) {
                that.editor = editor;
                that.setEditorInstance();

                //Fixed Line break for spacebar and multiple mathequations
                editor.on("BeforeSetContent", function (event) {
                  let content = event.content;
                  content = content.replace(
                    /<span>(?:&nbsp;\s*)+<math/g,
                    "<span><math"
                  );
                  content = content.replace(
                    /<\/math><\/p>\s*<p><math/g,
                    `</math></p><br/><p><math`
                  );
                  content = content.replace(
                    /<\/math><\/span><span><span>​<\/span><\/span><\/p>\n<p><math/g,
                    "</math></span><br/></p>\n<p><math"
                  );
                  content = content.replace(
                    /<\/math><\/span><span><\/span><\/p>\s*<p><math/g,
                    "</math></span><br/></p><p><math"
                  );
                  event.content = content;
                });

                //Block unsecured protocol http on link plugin
                editor.on("BeforeSetContent", function (event) {
                  const content = event.content;
                  const httpLinks = content.match(/<a href="http:\/\/[^"]*"/g);
                  const httpBlobImageLinks = content.match(
                    /<img src="blob:http:\/\/[^"]*"/g
                  );
                  const httpImageLinks = content.match(
                    /<img src="http:\/\/[^"]*"/g
                  );
                  const httpVideoLinks = content.match(
                    /<iframe src="http:\/\/[^"]*"/g
                  );

                  if (
                    httpLinks ||
                    httpBlobImageLinks ||
                    httpImageLinks ||
                    httpVideoLinks
                  ) {
                    editor.windowManager.alert(
                      "HTTP links are not allowed. Please use HTTPS links instead."
                    );
                    event.preventDefault();
                  }
                });

                editor.ui.registry.addIcon(
                  "math-icon",
                  `<svg width="26" height="26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.315 21.36c-.364 0-.681-.047-.952-.14v-1.008c.28.112.56.168.84.168.803 0 1.204-.532 1.204-1.596V8.102c0-1.661.751-2.492 2.254-2.492.383 0 .677.042.882.126v1.022a1.976 1.976 0 0 0-.784-.168c-.775 0-1.162.532-1.162 1.596v10.682c0 1.661-.76 2.492-2.282 2.492ZM10.126 22.4v-.55l3.06-4.47-2.98-3.96v-.56h5.35v.79h-4.09l2.78 3.71-2.9 4.25h4.53v.79h-5.75ZM18.37 20.1c-.573 0-1.014-.163-1.32-.49-.3-.333-.45-.813-.45-1.44 0-.367.05-.723.15-1.07.1-.353.243-.673.43-.96.186-.287.407-.517.66-.69.38-.26.817-.39 1.31-.39.293 0 .553.067.78.2.233.127.423.303.57.53.006-.06.01-.12.01-.18.006-.067.01-.13.01-.19-.034-1.267-.48-1.9-1.34-1.9a2.318 2.318 0 0 0-1.26.4v-.84a3.46 3.46 0 0 1 1.38-.3c.686 0 1.206.223 1.56.67.36.447.54 1.113.54 2 0 .567-.063 1.117-.19 1.65-.12.527-.294 1-.52 1.42-.22.42-.48.757-.78 1.01-.447.38-.96.57-1.54.57Zm.05-.74c.306 0 .59-.117.85-.35.267-.233.493-.55.68-.95a4.76 4.76 0 0 0 .4-1.37 1.233 1.233 0 0 0-.41-.68c-.2-.18-.43-.27-.69-.27-.34 0-.64.117-.9.35a2.45 2.45 0 0 0-.62.91c-.147.367-.22.76-.22 1.18 0 .373.076.663.23.87.16.207.387.31.68.31ZM11.63 11.17l-.8-.11-.03-.09a12.92 12.92 0 0 0 1.41-1.9c.388-.633.68-1.263.88-1.89h-.81a.969.969 0 0 0-.42.09c-.12.053-.246.16-.38.32-.126.153-.276.38-.45.68l-.26-.11c.2-.527.384-.933.55-1.22.114-.187.22-.33.32-.43.1-.107.224-.18.37-.22.154-.04.36-.06.62-.06h3.89c.28 0 .508-.023.68-.07.18-.053.378-.163.59-.33l.11.15-.52.73c-.106.153-.206.27-.3.35a.505.505 0 0 1-.34.12h-.75a2.113 2.113 0 0 0-.12.38c-.052.2-.11.43-.17.69-.052.26-.1.51-.14.75-.032.24-.05.423-.05.55 0 .52.17.78.51.78.2 0 .424-.113.67-.34l.08.27a3.8 3.8 0 0 1-.84.62c-.3.16-.572.24-.82.24-.292 0-.5-.067-.62-.2-.112-.14-.17-.35-.17-.63 0-.133.01-.287.03-.46.028-.173.064-.363.11-.57.048-.207.114-.483.2-.83.094-.347.214-.763.36-1.25h-1.39c-.652 1.753-1.32 3.083-2 3.99Z" fill="#293E98"/></svg>
                  `
                );

                editor.ui.registry.addButton("Math", {
                  text: "Symbols",
                  icon: "math-icon",
                  tooltip: "Symbols palette",
                  onAction: function (_) {
                    that.setEditorOpen(true);
                  },
                });

                editor.ui.registry.addButton("Focus", {
                  text: "Focus",
                  tooltip: "Focus editor",
                  onAction: function (_) {
                    that.editor.focus();
                  },
                });

                editor.ui.registry.addButton("Refresh", {
                  icon: "reload",
                  tooltip: "Refresh editor",
                  onAction: function (_) {
                    if (typeof that.props.refreshEditor === "function") {
                      that.props.refreshEditor();
                    }
                  },
                });

                editor.ui.registry.addMenuItem("Math", {
                  text: "Math equation",
                  tooltip: "Math equation",
                  onAction: function () {
                    that.setEditorOpen(true);
                  },
                });

                editor.on("OpenWindow", function (eventDetails) {
                  const dialogTitle = $(".tox-dialog__title").text();

                  if (dialogTitle === "Insert/Edit Image") {
                    $(":button.tox-button[title='Save']").html("Insert"); //Chenge Save lable to Insert
                    $(
                      ".tox-dialog__body-nav-item.tox-tab:contains('General')"
                    ).html("Embed"); //Change General lable to Embed
                    $(".tox-label:contains('Source')").html("Link"); //Change Source lable to Link
                    $(".tox-dialog__body-nav-item.tox-tab:contains('Upload')")
                      .click()
                      .focus();
                  }
                });
              },
              external_plugins: {
                mathjax:
                  "https://learningapps-ui-ppe.pearson.com/b0/qa-int/lass-ui-cdn-libs/latest/tinymce-mathjax/v1.0.8/plugin.js",
              },
              mathjax: {
                //lib: "https://learningapps-ui-ppe.pearsoned.com/b0/dev/webcomponents-shared/latest/matheditor/mathjax/es5/tex-chtml-full.js", //required path to mathjax
                lib: "https://learningapps-ui-ppe.pearson.com/b0/qa-int/lass-ui-cdn-libs/latest/matheditor/js/mathjax.js",
                configUrl:
                  "https://learningapps-ui-ppe.pearson.com/b0/qa-int/lass-ui-cdn-libs/latest/tinymce-mathjax/v1.0.8/config.js",
              },
            }}
            onEditorChange={this.handleEditorChange}
            onBeforeSetContent={this.onBeforeSetContent}
          />
        </div>
      </>
    );
  }
}

TextEditor.propTypes = {
  mathEditor: PropTypes.bool,
  initialContent: PropTypes.string,
  tinymceApiKey: PropTypes.string,
  onChange: PropTypes.func,
  toolbar: PropTypes.string,
  insertItems: PropTypes.string,
  height: PropTypes.number,
  setEditorInstance: PropTypes.func,
  refreshEditor: PropTypes.func,
  showMenu: PropTypes.bool,
  statusbar: PropTypes.bool,
  loader: PropTypes.bool,
  quickbarsSelectionToolbar: PropTypes.string,
  autoFocus: PropTypes.bool,
};

TextEditor.defaultProps = {
  mathEditor: true,
  // tinymceApiKey: "8rx0rjuz205wkmwv32ddw2a1hovrsavxlnx12rrkk4e594wb",
  height: 600,
  toolbar:
    "Focus | undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview  print | insertfile image media link anchor codesample Math code | ltr rtl",
  insertItems:
    "image link media inserttable Math codesample | charmap emoticons hr | pagebreak anchor | insertdatetime",
  showMenu: true,
  statusbar: true,
  loader: false,
  quickbarsSelectionToolbar:
    "bold italic | quicklink h2 h3 blockquote quicktable",
  autoFocus: false,
};

export default TextEditor;
