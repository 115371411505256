import React, { FunctionComponent } from "react";
import Pagination from "../Pagination";
import DropDownList from "../DropDownList";
import ThemeProvider from "./../ThemeProvider";
import styled, { css } from "styled-components";

type Option = {
  value: number;
  display: number;
};

enum initialPages {
  four = 4,
  eight = 8,
}

enum Theme {
  default = "default",
  wsol = "wsol",
}

type Props = {
  totalPages: number;
  currentPage: number;
  selectedOption: number;
  optionList: Option[];
  rowType: string;
  totalRows: number;
  onSelectDropdown: (selectedOption: number) => void;
  onSelectPagination: (selectedPage: number) => void;
  theme: Theme;
};

const TablePagination: FunctionComponent<Props> = (props) => {
  return (
    <ThemeProvider.Consumer>
    {(context) => (
    <TableFooter context={context} theme={props.theme}>
      <TableFooterDropdown context={context} theme={props.theme}>
        <TableFooterTotal context={context} theme={props.theme}>
          Viewing {props.totalRows} {props.rowType}
        </TableFooterTotal>
        <TableFooterPaginationDropdown
          context={context} theme={props.theme}>
          <TFPaginationDropdownLabel
            context={context} theme={props.theme}>
            {props.theme === "wsol" ? "students per page" : "Rows per page:"}
          </TFPaginationDropdownLabel>
          <DropDownListWrapper
            context={context} theme={props.theme}
            id={`rowDropdown_${Date.now()}`}
            label={props.selectedOption}
            triggerType="box"
            boxWidth="75px"
            menuWidth="160px"
            selectedOption={props.selectedOption.toString()}
            isCheckboxEnabled={true}
            onGetSelectedOption={props.onSelectDropdown}
            options={props.optionList}
          />
        </TableFooterPaginationDropdown>
      </TableFooterDropdown>

      <Pagination
        type={
          initialPages.four * 2 - 2 <= props.totalPages
            ? "FASTFORWARD"
            : "MAXIMUM"
        }
        currentPageNum={props.currentPage}
        totalPages={props.totalPages}
        handlePage={props.onSelectPagination}
        initialPageCount={
          props.totalPages <= initialPages.four
            ? props.totalPages
            : initialPages.four
        }
      />
    </TableFooter>
    )}
    </ThemeProvider.Consumer>
  );
};

const DropDownListWrapper = styled(DropDownList)`
  ${(props) => css`
    ${props.context?.styles ? props.context.styles.tableStyles.DropDownListWrapper : null}
  `}
`

const TableFooter = styled.div`
  ${(props) => css`
    ${props.context?.styles ? props.context.styles.tableStyles.TableFooter : null}
  `}
`;

const TableFooterTotal = styled.p`
  ${(props) => css`
    ${props.context?.styles ? props.context.styles.tableStyles.TableFooterTotal : null}
  `}
`;

const TableFooterDropdown = styled.div`
  ${(props) => css`
    ${props.context?.styles ? props.context.styles.tableStyles.TableFooterDropdown : null}
  `}
`;

const TableFooterPaginationDropdown = styled.div`
  ${(props) => css`
    ${props.context?.styles ? props.context.styles.tableStyles.TableFooterPaginationDropdown : null}
  `}
`;

const TFPaginationDropdownLabel = styled.p`
  ${(props) => css`
    ${props.context?.styles ? props.context.styles.tableStyles.TFPaginationDropdownLabel : null}
  `}
`;

export default TablePagination;
