import React from "react";
import PropTypes from "prop-types";
import FileUploadProgressCircle from "./FileUploadProgressCircle";
import FileTitle from "./FileTitle";
import Icon from "../../../Icon";
import { PROCESSING, COMPLETED } from "../const/uploader";
import styles from "../file-upload.module.scss";
export const SingleFile = ({
  file,
  deleteFile,
  index,
  setErrorMessage,
  id,
  hideRemoveButton
}) => {
  let isFileProcessingOrCompleted =
    file.status === PROCESSING || file.status === COMPLETED;
  let svgStyle = { width: "39px", height: "39px" };
  return (
    <div className={styles.singleFile}>
      <div className={styles.progressCircle}>
        <FileUploadProgressCircle
          file={file}
          styles={{
            isProgressTextEnabled: !isFileProcessingOrCompleted,
            svgStyle: svgStyle,
          }}
          isCheckMarkVisible={file.status === COMPLETED}
          isCircleHidden={false}
        />
      </div>
      <FileTitle
        FileNameStyle={styles.fileNameStatusMulti}
        uploadStatus={file.status}
        fileName={file.fileName}
        fileSize={file.fileSize}
        uploadProgress={file.uploadProgress}
      >
        {hideRemoveButton === true && file.status !== COMPLETED ? null :
          <button
          id={`delete-${id}-${file.fileId}`}
          className={`${styles.iconBtn} ${styles.deleteIcon}`}
          aria-label={`delete this file ${file.fileName}`}
          onClick={(e) => {
            setErrorMessage("");
            deleteFile(index);
            e.preventDefault();
          }}
          >
          <Icon
            fileName="delete-18"
            name="delete the file"
            title="delete the file"
          />
        </button>}
      </FileTitle>
    </div>
  );
};

SingleFile.propTypes = {
  index: PropTypes.number.isRequired,
  file: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  deleteFile: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};

export default SingleFile;
