import React, { FunctionComponent, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import ThemeProvider from "./../ThemeProvider";
import Icon from "../Icon";
import Button from "../Button";
import FocusTrap from "focus-trap-react";
import BUTTON_SIZES from "../../const/BUTTON_SIZES";

type Props = {
  variant?: string; // Default, Confirmation
  id: string;
  heading?: string;
  body?: string | HTMLElement;
  onClose?: () => Function;
  onProceed?: () => void;
  show: boolean;
  dismissible?: boolean;
  successButtonLable?: string;
  cancelButtonLable?: string;
  className?: string;
  children?: HTMLElement;
  designSystem?: string;
};


const ConfirmationModal: FunctionComponent<Props> = (props) => {

  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 400px)").matches
  )

  useEffect(() => {
    const parentClose = props.onClose();
    const close = (e) => {
      if (e.keyCode === 27) {
        parentClose();
      }
    };

    window
    .matchMedia("(max-width: 400px)")
    .addEventListener('change', e => setMatches( e.matches ));

    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  });

  return (
    props.show && (
      <>
      <ThemeProvider.Consumer>
      {(context) => (
        <div className="pe-lass-modal-container" id={props.id}>
          <LassModalOverlay context={context}></LassModalOverlay>
          <FocusTrap>
            <LassModal
              context={context}
              className={props.className}
              role="dialog"
              aria-labelledby={`${props.id}-pe-lass-dialog-heading`}
              aria-describedby={`${props.id}-pe-lass-dialog-description`}
              aria-modal="true"
            >
              {props.dismissible && (
                // <div className="close-title">
                  <ModalButton
                    designSystem={props.designSystem}
                    context={context}
                    onClick={props.onClose()}
                    varient={"icon"}
                    size={BUTTON_SIZES.SMALL}
                    aria-label="Close dialog"
                  >
                    <Icon fileName={"incorrect-18"} color={(props.designSystem === 'missionzero') ? '#3E4C59' : '#6a7070' } 
                      name="close-dialog"
                      title="Close Dialog"
                      focusable={false}
                      isHidden={true} />
                  </ModalButton>
                // </div>
              )}
              {props.heading && <LassModalH1 context={context} id={`${props.id}-pe-lass-dialog-heading`}>
                {props.heading}
              </LassModalH1>}
              {props.body && <LassModalBody context={context} id={`${props.id}-pe-lass-dialog-description`}>
                {typeof props.body === "string" ||
                props.body instanceof String ? (
                  <p>{props.body}</p>
                ) : (
                  props.body
                )}
              </LassModalBody>}
              {props.children}
              <LassActions context={context}>
                {props.cancelButtonLable && (
                  <Button
                    varient={"default"}
                    size={matches ? BUTTON_SIZES.SMALL : BUTTON_SIZES.LARGE}
                    onClick={props.onClose()}
                  >
                    {props.cancelButtonLable}
                  </Button>
                )}
                {props.successButtonLable && (
                  <Button
                    varient={"primary"}
                    size={matches ? BUTTON_SIZES.SMALL : BUTTON_SIZES.LARGE}
                    onClick={props.onProceed()}
                  >
                    {props.successButtonLable}
                  </Button>
                )}
              </LassActions>
            </LassModal>
          </FocusTrap>
        </div>)}
        </ThemeProvider.Consumer>
      </>
    )
  );
};

const LassModalOverlay = styled.div`
  ${(props) => css`
    ${props.context && props.context.styles ? props.context.styles.confirmationModalStyles.LassModalOverlay : null}
  `}
`
const LassModal = styled.div`
  ${(props) => css`
    ${props.context && props.context.styles ? props.context.styles.confirmationModalStyles.LassModal : null}
  `}
`
const LassModalH1 = styled.h1`
  ${(props) => css`
    ${props.context && props.context.styles ? props.context.styles.confirmationModalStyles.LassModalH1 : null}
  `}
`
const LassModalBody = styled.div`
  ${(props) => css`
    ${props.context && props.context.styles ? props.context.styles.confirmationModalStyles.LassModalBody : null}
  `}
`
const LassActions = styled.div`
  ${(props) => css`
    ${props.context && props.context.styles ? props.context.styles.confirmationModalStyles.LassActions : null}
  `}
`

const ModalButton = styled(Button)`
  position: absolute !important;
  background: none;
  border: 2px solid transparent !important;
  color: inherit;
  cursor: pointer;
  ${(props) => props.designSystem === 'gravity' && `
  padding: 8px !important;
  top: 5px;
  right: 5px;
  height:50px !important;
    &:focus {
      outline: 0 !important;
      border: 2px solid #0b73da !important;
      border-radius: 4px;
    }
    &:hover {
      border-radius: 2px;
    }
  `}
  ${(props) => props.designSystem === 'missionzero' && `
    padding: 5px !important;
    top: 4px;
    right: 4px;
  `}
`;

ConfirmationModal.defaultProps = {
  show: true,
  dismissible: true,
  variant: "Default",
  designSystem: 'gravity'
};

export default ConfirmationModal;
