import React from "react";
import TableHeader from "./TableHeader.tsx";
import CheckBox from "./../CheckBox";
import TablePagination from "./TablePagination.tsx";
import Pagination from "../Pagination";
import { generateId } from "./../_util/common.js";
import ThemeProvider from "./../ThemeProvider";
import styled, { css } from "styled-components";

type Column = {
  title: string;
  dataIndex: string;
  key: number;
  width?: string | number;
  displayValue?: any;
};

enum SortingOrder {
  asc = "asc",
  desc = "desc",
  sortable = "sortable",
}

enum dateType {
  start = "start",
  due = "due",
}

enum Theme {
  default = "default",
  wsol = "wsol",
}

type Row = {
  key: number;
};

type Option = {
  value: number;
  display: number;
};

type sortingValues = {
  dataIndex: string;
  direction: string;
};

type Pagination = {
  dataRowsCount: number;
  rowType: string;
  onChangePageNumber?: (
    pageNumber: number,
    rowsPerPage: number,
    sorting: sortingValues
  ) => void;
  onChangeRowsPerPage?: (rowsPerPage: number) => void;
};

type Filter = {
  title: string;
  value: string;
  logic: (record: Row) => Row;
};

enum HeaderType {
  default = "default",
  basic = "basic",
}

type Props = {
  captionTitle?: string;
  columns: Column[];
  dataSource: Row[];
  rowSelection?: {
    onSelect: (selectedRows: Row[]) => void;
    selectedRows?: number[];
    filter?: Filter[];
    hideSelectLabel?: boolean;
    columnWidth?: string;
    selectedColor?: string;
  };
  pagination?: Pagination;
  optionList?: Option[];
  onClickRow?: (data: Row) => null;
  onChangeSorting?: (sortingData: sortingValues, rowsPerPage: number) => void;
  theme?: Theme;
  rowHoverColor?: string;
  headerType?: HeaderType;
  designSystem?: String;
};

type State = {
  dataSource: Row[];
  sorting: {
    dataIndex: string;
    direction: SortingOrder;
  };
  selection: {
    selectedRows: number[];
    selectedAll: boolean;
    selectedFilter?: string;
    hideSelectLabel?: boolean;
    columnWidth?: string;
  };
  pagination?: {
    totalPages: number;
    dropdownOption: number;
    rowsPerPage: number;
    currentPageNumber: number;
  };
  lastPageRows?: number;
  dataSourceChunk?: Row[];
  dataOnDemand: boolean;
  uuid: string;
};

class Table extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: props.dataSource,
      sorting: {
        dataIndex: null,
        direction: SortingOrder.sortable,
      },
      selection: {
        selectedRows:
          (props.rowSelection && props.rowSelection.selectedRows) || [],
        selectedAll: false,
        selectedFilter: null,
        hideSelectLabel:
          (props.rowSelection && props.rowSelection.hideSelectLabel) || false,
        columnWidth:
          props.rowSelection && props.rowSelection.columnWidth
            ? props.rowSelection.columnWidth
            : "",
      },
      pagination: {
        totalPages: null,
        dropdownOption: props.pagination && props.optionList[0].display,
        rowsPerPage: props.pagination && props.optionList[0].display,
        currentPageNumber: 1,
      },
      dataSourceChunk: [],
      lastPageRows: null,
      dataOnDemand: false,
      uuid: generateId(),
    };
  }

  sortData = (data: Row[], dataIndex: string, direction: string) => {
    if (
      dataIndex &&
      direction &&
      (dataIndex === dateType.start || dataIndex === dateType.due)
    ) {
      data.sort(function (a, b) {
        var key1 = new Date(dataIndex === dateType.start ? a.start : a.due);
        var key2 = new Date(dataIndex === dateType.start ? b.start : b.due);

        if (key1 < key2) {
          return direction == SortingOrder.desc ? -1 : 1;
        } else if (key1 == key2) {
          return 0;
        } else {
          return direction == SortingOrder.desc ? 1 : -1;
        }
      });
    } else {
      data.sort(this.nullSort(direction === SortingOrder.asc, dataIndex));
    }

    return data;
  };

  nullSort = (descending = false, dataIndex: string) => {
    const naturalCollator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: "base",
    });

    return function (a, b) {
      if (
        (a[dataIndex].value || a[dataIndex]) ===
        (b[dataIndex].value || b[dataIndex])
      ) {
        return 0;
      }
      if (a[dataIndex] === null || a[dataIndex].value === null) {
        return 1;
      }
      if (b[dataIndex] === null || b[dataIndex].value === null) {
        return -1;
      }

      let ret;

      ret = naturalCollator.compare(
        a[dataIndex].value || a[dataIndex],
        b[dataIndex].value || b[dataIndex]
      );

      if (descending) {
        ret = -ret;
      }

      return ret;
    };
  };

  onSort = (dataIndex: string) => {
    const sorting = {
      dataIndex: dataIndex,
      direction:
        dataIndex === this.state.sorting.dataIndex
          ? this.state.sorting.direction === SortingOrder.asc
            ? SortingOrder.desc
            : SortingOrder.asc
          : SortingOrder.desc,
    };
    let sortedDataSource = [...this.state.dataSource];
    sortedDataSource = this.sortData(
      sortedDataSource,
      sorting.dataIndex,
      sorting.direction
    );
    this.setState({
      sorting: { ...sorting },
      dataSource: sortedDataSource,
    });

    if (this.props.onChangeSorting) {
      this.props.onChangeSorting(sorting, this.state.pagination.rowsPerPage);
    }

    if (this.props.pagination) {
      this.setState((prevState) => ({
        pagination: {
          ...prevState.pagination,
          currentPageNumber: 1,
        },
      }));
    }
  };

  renderTableRow = (data: Row, context: any) => {
    const active = this.state.selection.selectedRows.includes(data.key);
    const row = this.props.columns.map((col, index) => {
      return (
        <TableBodyCell
          context={context}
          theme={this.props.theme}
          active={active}
          key={index}
        >
          {data[col.dataIndex] && data[col.dataIndex].displayValue
            ? data[col.dataIndex].displayValue
            : data[col.dataIndex]}
        </TableBodyCell>
      );
    });
    return row;
  };

  generateSelectedRowsByFilterValue = (status: string) => {
    const logic =
      this.props.rowSelection.filter &&
      this.props.rowSelection.filter.find((row) => {
        return row.value === status;
      });

    const response =
      status === "all"
        ? this.state.dataSource.map((data) => {
            return data;
          })
        : status === "none"
        ? []
        : this.state.dataSource.filter(logic.logic);

    return response;
  };

  generateSelectedRowsByFilter = (status: string) => {
    const logic =
      this.props.rowSelection.filter &&
      this.props.rowSelection.filter.find((row) => {
        return row.value === status;
      });

    const response =
      status === "all"
        ? this.state.dataSource.map((data) => {
            return data;
          })
        : status === "none"
        ? []
        : this.state.dataSource.filter(logic.logic);

    return response;
  };

  generateSelectedRowKeysByFilter = (filter: Filter) => {
    const responseArray = this.state.dataSource.filter(filter.logic);
    const responseArrayKeys = responseArray.map((row) => row.key);

    return responseArrayKeys;
  };

  generateSelectedOption = (selection, filters: Filter[]) => {
    let response = "";

    for (let i = 0; i < filters.length; i++) {
      const filteredArray = this.generateSelectedRowKeysByFilter(filters[i]);
      if (
        JSON.stringify(filteredArray.sort()) ===
        JSON.stringify(selection.selectedRows.sort())
      ) {
        response = filters[i].value;
        break;
      }
    }
    return response;
  };

  onSelect = (key: number) => {
    let newSelection = {
      selectedRows: this.state.selection.selectedRows,
      selectedAll: false,
      selectedFilter: null,
      hideSelectLabel: this.state.selection.hideSelectLabel,
    };

    const index = newSelection.selectedRows.findIndex(
      (element) => element === key
    );
    if (index < 0) {
      newSelection.selectedRows.push(key);
    } else {
      newSelection.selectedRows.splice(index, 1);
    }

    newSelection.selectedAll =
      newSelection.selectedRows.length === this.state.dataSource.length;

    if (this.props.rowSelection.filter !== undefined) {
      newSelection.selectedFilter = newSelection.selectedAll
        ? "all"
        : newSelection.selectedRows.length === 0
        ? "none"
        : this.generateSelectedOption(
            newSelection,
            this.props.rowSelection.filter
          );
    }

    this.setState(
      {
        selection: newSelection,
      },
      () => {
        let responseArray = this.state.dataSource.map((data) => {
          if (newSelection.selectedRows.includes(data.key)) {
            return data;
          }
        });
        responseArray = responseArray.filter(function (e) {
          return e;
        });
        this.props.rowSelection.onSelect(responseArray);
      }
    );
  };

  renderSelectBox = (data: Row, index: number, context: any) => {
    const active = this.state.selection.selectedRows.includes(data.key);
    const { selectedRows, hideSelectLabel } = this.state.selection;
    return (
      <TableBodyCell context={context} theme={this.props.theme} active={active}>
        <CheckBox
          context={context}
          designSystem={this.props.designSystem}
          theme={this.props.theme}
          checked={selectedRows.includes(data.key)}
          groupName="CheckBox-Group-One"
          id={`checkbox_${this.state.uuid}_${index}`}
          value={data.key}
          onCheckboxChange={(val, e) => {
            this.onSelect(data.key);
          }}
          size="18"
          ariaLabel={`select Row`}
        >
          {!hideSelectLabel && `select row ${index + 1}`}
        </CheckBox>
      </TableBodyCell>
    );
  };

  onSelectDropdown = (selectOption: number) => {
    if (this.props.pagination && this.props.pagination.onChangeRowsPerPage) {
      this.props.pagination.onChangeRowsPerPage(selectOption);
    }

    this.setState((prevState) => ({
      pagination: {
        ...prevState.pagination,
        totalPages: Math.ceil(
          this.props.pagination.dataRowsCount / selectOption
        ),
        dropdownOption: selectOption,
        rowsPerPage: selectOption,
        currentPageNumber: 1,
      },
    }));
  };

  sendDataSourceChunk = (
    pageNumber: number,
    rowsPerPage: number,
    dropdownOption: number
  ) => {
    if (this.state.dataOnDemand) {
      return this.state.dataSource;
    } else {
      let start = (pageNumber - 1) * dropdownOption;
      let end = (pageNumber - 1) * dropdownOption + Number(rowsPerPage);
      let dataChunk = this.state.dataSource.slice(start, end);
      return dataChunk;
    }
  };

  onSelectPagination = (selectedPage: number) => {
    if (this.props.pagination && this.props.pagination.onChangePageNumber) {
      this.props.pagination.onChangePageNumber(
        selectedPage,
        this.state.pagination.rowsPerPage,
        this.state.sorting
      );
    }

    if (this.state.pagination.currentPageNumber !== selectedPage) {
      this.setState((prevState) => ({
        pagination: {
          ...prevState.pagination,
          currentPageNumber: selectedPage,
        },
      }));
    }
  };

  renderPagination = () => {
    let paginationProps = {
      rowType: this.props.pagination.rowType,
      optionList: this.props.optionList,
      onSelectDropdown: this.onSelectDropdown,
      onSelectPagination: this.onSelectPagination,
      selectedOption: this.state.pagination.dropdownOption,
      totalPages: this.state.pagination.totalPages,
      currentPage: this.state.pagination.currentPageNumber,
      totalRows: this.props.pagination.dataRowsCount,
      theme: this.props.theme,
    };
    return (
      <td
        colSpan={
          this.props.rowSelection
            ? this.props.columns.length + 1
            : this.props.columns.length
        }
      >
        <TablePagination {...paginationProps} />
      </td>
    );
  };

  selectAllRows = (status: string) => {
    const selectedRows = this.generateSelectedRowsByFilterValue(status);

    const selectedRowKeys = selectedRows.map((row) => row.key);

    const selectionData = {
      selectedAll: status === "all" ? true : false,
      selectedRows: selectedRowKeys,
      selectedFilter: status,
      hideSelectLabel: this.state.selection.hideSelectLabel,
    };
    this.setState(
      {
        selection: selectionData,
      },
      () => {
        this.props.rowSelection.onSelect(selectedRows);
      }
    );
  };

  renderBodyWithoutPagination = (context) => {
    return this.state.dataSource.map((data, index) => {
      const active = this.state.selection.selectedRows.includes(data.key);
      return (
        <TableBodyRow
          context={context}
          theme={this.props.theme}
          active={active}
          beforeActive={
            active &&
            this.state.dataSource[index + 1] &&
            !this.state.selection.selectedRows.includes(
              this.state.dataSource[index + 1].key
            )
          }
          onClick={() => this.props.onClickRow && this.props.onClickRow(data)}
          key={index}
          tabIndex={this.props.onClickRow ? 0 : -1}
          role={this.props.onClickRow ? "button" : ""}
          onKeyPress={(event) => {
            if (event.key === "Enter" && this.props.onClickRow) {
              this.props.onClickRow(data);
            }
          }}
        >
          {this.props.rowSelection
            ? this.renderSelectBox(data, index, context)
            : null}
          {this.renderTableRow(data, context)}
        </TableBodyRow>
      );
    });
  };

  renderBodyWithPagination = (context) => {
    let dataSourceChunk = [];
    let { totalPages, dropdownOption, currentPageNumber, rowsPerPage } =
      this.state.pagination;

    if (
      currentPageNumber !== totalPages ||
      (this.props.pagination.dataRowsCount % dropdownOption === 0 &&
        currentPageNumber === totalPages)
    ) {
      dataSourceChunk = this.sendDataSourceChunk(
        currentPageNumber,
        rowsPerPage,
        dropdownOption
      );
    } else {
      const lastPageRows = this.props.pagination.dataRowsCount % dropdownOption;
      dataSourceChunk = this.sendDataSourceChunk(
        currentPageNumber,
        lastPageRows,
        dropdownOption
      );
    }

    return dataSourceChunk.map((data, index) => {
      const active = this.state.selection.selectedRows.includes(data.key);
      return (
        <TableBodyRow
          context={context}
          theme={this.props.theme}
          active={active}
          beforeActive={
            active &&
            dataSourceChunk[index + 1] &&
            !this.state.selection.selectedRows.includes(
              dataSourceChunk[index + 1].key
            )
          }
          onClick={(e) => {
            if (this.props.onClickRow) {
              this.props.onClickRow(data);
            }
          }}
          key={index}
          tabIndex={this.props.onClickRow ? 0 : -1}
          role={this.props.onClickRow ? "button" : ""}
          onKeyPress={(event) => {
            if (event.key === "Enter" && this.props.onClickRow) {
              this.props.onClickRow(data);
            }
          }}
        >
          {this.props.rowSelection
            ? this.renderSelectBox(data, index, context)
            : null}
          {this.renderTableRow(data, context)}
        </TableBodyRow>
      );
    });
  };

  componentDidMount() {
    if (this.props.rowSelection) {
      let newSelection = {
        selectedRows: this.state.selection.selectedRows,
        selectedAll: false,
        selectedFilter: null,
        hideSelectLabel: this.state.selection.hideSelectLabel,
      };

      newSelection.selectedAll =
        newSelection.selectedRows.length === this.state.dataSource.length;
      if (this.props.rowSelection.filter !== undefined) {
        newSelection.selectedFilter = newSelection.selectedAll
          ? "all"
          : newSelection.selectedRows.length === 0
          ? "none"
          : this.generateSelectedOption(
              newSelection,
              this.props.rowSelection.filter
            );
      }

      this.setState({
        selection: newSelection,
      });
    }

    if (this.props.pagination) {
      let initialPagination = {
        totalPages: Math.ceil(
          this.props.pagination.dataRowsCount / this.props.optionList[0].value
        ),
        dropdownOption: this.props.optionList[0].value,
        currentPageNumber: 1,
        rowsPerPage: this.props.optionList[0].value,
      };

      const dataOnDemand =
        this.props.pagination.dataRowsCount !== this.props.dataSource.length;

      this.setState({ pagination: initialPagination, dataOnDemand });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { dropdownOption } = this.state.pagination;

    if (
      prevProps.dataSource !== this.props.dataSource &&
      this.props.pagination
    ) {
      const dataOnDemand =
        this.props.pagination.dataRowsCount !== this.props.dataSource.length;
      this.setState({
        dataSource: this.props.dataSource,
        pagination: {
          ...this.state.pagination,
          currentPageNumber:
            this.props.pagination.dataRowsCount !==
            prevProps.pagination.dataRowsCount
              ? 1
              : this.state.pagination.currentPageNumber,
          totalPages: Math.ceil(
            this.props.pagination.dataRowsCount / dropdownOption
          ),
        },
        dataOnDemand,
      });
    } else if (prevProps.dataSource !== this.props.dataSource) {
      this.setState({
        dataSource: this.props.dataSource,
      });
    } else if (prevProps.pagination !== this.props.pagination) {
      const dataOnDemand =
        this.props.pagination.dataRowsCount !== this.props.dataSource.length;
      this.setState({
        pagination: {
          ...this.state.pagination,
          currentPageNumber:
            this.props.pagination.dataRowsCount !==
            prevProps.pagination.dataRowsCount
              ? 1
              : this.state.pagination.currentPageNumber,
          totalPages: Math.ceil(
            this.props.pagination.dataRowsCount / dropdownOption
          ),
        },
        dataOnDemand,
      });
    }
  }

  render() {
    return (
      <ThemeProvider.Consumer>
        {(context) => (
          <TableComponent
            context={context}
            theme={this.props.theme}
            className={this.props.className}
          >
            <caption className="visually-hidden">{this.props.captionTitle}</caption>
            <TableHeader
              context={context}
              columns={this.props.columns}
              sortingData={{
                ...this.state.sorting,
                ...{ onSort: this.onSort },
              }}
              headerType={this.props.headerType || "basic"}
              selectionData={
                this.props.rowSelection
                  ? {
                      onSelect: this.selectAllRows,
                      selectedAll: this.state.selection.selectedAll,
                      mixedState:
                        !this.state.selection.selectedAll &&
                        this.state.selection.selectedRows.length > 0,
                      filter: this.props.rowSelection.filter,
                      selectedFilter: this.state.selection.selectedFilter,
                      hideSelectLabel: this.state.selection.hideSelectLabel,
                      columnWidth: this.state.selection.columnWidth,
                    }
                  : null
              }
              theme={this.props.theme}
              uuid={this.state.uuid}
              designSystem={this.props.designSystem}
            ></TableHeader>
            <tbody>
              {this.props.pagination
                ? this.renderBodyWithPagination(context)
                : this.renderBodyWithoutPagination(context)}
            </tbody>
            {this.props.pagination && (
              <tfoot>
                <tr>{this.renderPagination()}</tr>
              </tfoot>
            )}
          </TableComponent>
        )}
      </ThemeProvider.Consumer>
    );
  }

  public static defaultProps = {
    optionList: [
      { value: 10, display: 10 },
      { value: 50, display: 50 },
      { value: 100, display: 100 },
    ],
    theme: Theme.default,
    designSystem: "gravity",
  };
}

const TableComponent = styled.table`
  ${(props) => css`
    ${props.context?.styles
      ? props.context.styles.tableStyles.TableComponent
      : null}
  `}
`;

const TableBodyCell = styled.td`
  ${(props) => css`
    ${props.context?.styles
      ? props.context.styles.tableStyles.TableBodyCell
      : null}
  `}
`;

const TableBodyRow = styled.tr`
  ${(props) => css`
    ${props.context?.styles
      ? props.context.styles.tableStyles.TableBodyRow
      : null}
  `}
`;

export default Table;
