/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2018 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */
import React from "react";
import PropTypes from "prop-types";
import PaginationButton from "./common/PaginationButton";
import "./Pagination.scss";
import PaginationAnchor from "./PaginationAnchor";

const INC = 1;
const DEC = -1;
const NEW = "newPage";
const PREVIOUS = "previousPage";
const NEXT = "nextPage";
const FASTFORWARD = "fastforward";

class PaginationFastForward extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      pageNumbers: [],
      pageChanged: true,
      currentPage: this.props.currentPageNum,
      manualClick: false,
    };

    this.paginationItems = [];
  }

  updateRefArray = () => {
    const numOfItems = this.props.totalPages + 1;
    let i = 0;
    while (i < numOfItems) {
      this.paginationItems[i] = React.createRef();
      i++;
    }
  };

  handleInitialPageNumArray = () => {
    var pageNumArr = [];
    if (
      this.props.currentPageNum < this.props.initialPageCount ||
      this.props.currentPageNum === 1
    ) {
      for (var i = 0; i < this.props.initialPageCount - 1; i++) {
        pageNumArr.push(i + 1);
      }
      pageNumArr.push("e", this.props.totalPages);
    } else if (
      this.props.currentPageNum > this.props.initialPageCount - 1 &&
      this.props.currentPageNum <=
        this.props.totalPages - this.props.initialPageCount + 1
    ) {
      for (i = 0; i < this.props.initialPageCount - 3; i++) {
        pageNumArr.push(this.props.currentPageNum + i);
      }
      pageNumArr.push("e", this.props.totalPages);
      pageNumArr.unshift(1, "e");
    } else if (
      this.props.currentPageNum >
      this.props.totalPages - this.props.initialPageCount + 1
    ) {
      for (i = this.props.initialPageCount - 2; i > 0; i--) {
        pageNumArr.push(this.props.totalPages - i);
      }
      pageNumArr.push(this.props.totalPages);
      pageNumArr.unshift(1, "e");
    } else {
      console.log("Initial page value is invalid");
    }
    return pageNumArr;
  };

  componentDidMount() {
    var pageNumArr = this.handleInitialPageNumArray();
    this.setState({ pageNumbers: pageNumArr }, () => {
      this.updateRefArray();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPageNum !== this.props.currentPageNum) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
          currentPage: this.props.currentPageNum,
          pageChanged: true,
        },
        () => {
          var pageNumArr = this.handleInitialPageNumArray();
          this.setState({ pageNumbers: pageNumArr }, () => {
            this.updateRefArray();
          });
        }
      );
    }

    if (
      this.paginationItems &&
      this.paginationItems[this.state.currentPage] &&
      this.paginationItems[this.state.currentPage].current &&
      this.state.manualClick
    ) {
      this.paginationItems[this.state.currentPage].current.focus();
    }

    if (prevProps.totalPages !== this.props.totalPages) {
      var pageNumArr = this.handleInitialPageNumArray();
      this.setState(
        { pageNumbers: pageNumArr, pageChanged: true, currentPage: 1 },
        () => {
          this.updateRefArray();
        }
      );
    }
  }

  logicToHandlePagination = (type) => {
    let { currentPage } = this.state;
    let { initialPageCount, totalPages } = this.props;

    // creating middle changing values array and append other constant values to array
    if (
      type === INC &&
      currentPage > initialPageCount - 1 &&
      currentPage <= totalPages - initialPageCount + 2
    ) {
      this.incrementingArray();
    }
    if (
      type === DEC &&
      currentPage >= initialPageCount - 1 &&
      currentPage < totalPages - initialPageCount + 2
    ) {
      this.decrementingArray();
    }
    if (type === NEW) {
      this.newPageSelectedArray();
    }
  };

  // Setting up middle values of array when incrementing
  incrementingArray = () => {
    let { currentPage, pageNumbers } = this.state;
    let { initialPageCount, totalPages } = this.props;

    var middlePageNums = pageNumbers.slice(2, -2);
    if (currentPage === initialPageCount) {
      middlePageNums.forEach((num, i) => {
        middlePageNums[i] = num + initialPageCount - 3;
      });
    } else if (currentPage > totalPages - initialPageCount + 1) {
      middlePageNums.forEach((num, i) => {
        middlePageNums[i] = totalPages - initialPageCount + 2 + i;
      });
    } else {
      middlePageNums.forEach((num, i) => {
        middlePageNums[i] = num + 1;
      });
    }
    // append other array values and ellipsis to the array
    if (currentPage >= totalPages - initialPageCount + 2) {
      middlePageNums.push(totalPages - 1, totalPages);
    } else {
      middlePageNums.push("e", totalPages);
    }
    middlePageNums.unshift(1, "e");
    this.setState({ pageNumbers: middlePageNums });
  };

  // Setting up middle values of array when decrementing
  decrementingArray = () => {
    let { currentPage, pageNumbers } = this.state;
    let { initialPageCount, totalPages } = this.props;

    var middlePageNums = pageNumbers.slice(2, -2);
    if (currentPage === totalPages - initialPageCount + 1) {
      middlePageNums.forEach((num, i) => {
        middlePageNums[i] = num - (initialPageCount - 3);
      });
    } else if (
      currentPage <= initialPageCount - 1 &&
      middlePageNums.length !== 1
    ) {
      middlePageNums.forEach((num, i) => {
        middlePageNums[i] = 3 + i;
      });
    } else {
      middlePageNums.forEach((num, i) => {
        middlePageNums[i] = num - 1;
      });
    }
    // append other array values and ellipsis to the array
    if (currentPage === initialPageCount - 1) {
      middlePageNums.unshift(1, 2);
    } else {
      middlePageNums.unshift(1, "e");
    }
    middlePageNums.push("e", totalPages);
    this.setState({ pageNumbers: middlePageNums });
  };

  // Setting up middle values of array when new page is selected from pagination
  newPageSelectedArray = () => {
    let { currentPage, pageNumbers } = this.state;
    let { initialPageCount, totalPages } = this.props;

    var middlePageNums = pageNumbers.slice(2, -2);

    if (
      currentPage > totalPages - initialPageCount + 1 ||
      currentPage >= totalPages - 1
    ) {
      middlePageNums.forEach((num, i) => {
        middlePageNums[i] = totalPages - initialPageCount + 2 + i;
      });
    } else if (currentPage <= initialPageCount - 1) {
      middlePageNums.forEach((num, i) => {
        middlePageNums[i] = 3 + i;
      });
    } 
    // append other array values and ellipsis to the array
    if (currentPage >= totalPages - initialPageCount + 2) {
      middlePageNums.push(totalPages - 1, totalPages);
      middlePageNums.unshift(1, "e");
    } else if (currentPage <= initialPageCount - 1) {
      middlePageNums.unshift(1, 2);
      middlePageNums.push("e", totalPages);
    } else {
      middlePageNums.push("e", totalPages);
      middlePageNums.unshift(1, "e");
    }
    this.setState({ pageNumbers: middlePageNums });
  };

  nextPage = () => {
    this.setState(
      (prevState) => ({
        currentPage: prevState.currentPage + 1,
        pageChanged: true,
        manualClick: false,
      }),
      () => {
        this.logicToHandlePagination(INC);
        this.props.handlePage(this.state.currentPage, NEXT);
      }
    );
  };

  previousPage = () => {
    this.setState(
      (prevState) => ({
        currentPage: prevState.currentPage - 1,
        pageChanged: true,
        manualClick: false,
      }),
      () => {
        this.logicToHandlePagination(DEC);
        this.props.handlePage(this.state.currentPage, PREVIOUS);
      }
    );
  };

  newPage = (e) => {
    this.setState(
      (prevState) => ({
        currentPage: e,
        pageChanged: true,
        manualClick: true,
      }),
      () => {
        this.logicToHandlePagination(NEW);
        this.props.handlePage(this.state.currentPage, NEW);
      }
    );
  };

  // this will generate numbered Anchor tags
  numberATags = (currentPage, newPageFunc) => {
    const that = this;
    const anchorElements = this.state.pageNumbers.map(function (e, i) {
      if (e === currentPage) {
        return (
          <PaginationAnchor
            ref={that.paginationItems[e]}
            onClick={newPageFunc}
            current
            pageNumber={e}
            key={"laf" + i}
            type={FASTFORWARD}
          />
        );
      } else {
        return (
          <PaginationAnchor
            ref={that.paginationItems[e]}
            onClick={newPageFunc}
            pageNumber={e}
            key={"laf" + i}
            type={FASTFORWARD}
          />
        );
      }
    });
    return anchorElements;
  };

  render() {
    let { totalPages } = this.props;
    let { currentPage } = this.state;
    if (totalPages == null) {
      totalPages = "";
    }
    let disabledBackButton = this.state.currentPage <= 1;
    let disabledNextButton = this.state.currentPage === totalPages;
    let backAriaLabel = this.props.backAriaLabel;
    let nextAriaLabel = this.props.nextAriaLabel;
    if (disabledBackButton) {
      backAriaLabel += ", disabled currently";
    }
    if (disabledNextButton) {
      nextAriaLabel += ", disabled currently";
    }
    if (!(currentPage === 0 || currentPage > totalPages)) {
      return (
        <div
          className="ls-pagination"
          role="navigation"
          aria-label="pagination"
        >
          <PaginationButton
            ariaLabel={backAriaLabel}
            isDisabled={this.state.currentPage === 1}
            onClick={this.previousPage}
            iconFileName="next-18"
            iconName="back-page"
            iconTitle="Prior Page"
            buttonId="prevPage"
            svgClass="back-svg"
          />

          <span
            aria-live="polite"
            role="alert"
            className="sr-only"
          >{"page " + this.state.currentPage}</span>
          {this.state.pageChanged &&
            this.numberATags(this.state.currentPage, this.newPage)}

          <PaginationButton
            ariaLabel={nextAriaLabel}
            isDisabled={this.state.currentPage === totalPages}
            onClick={this.nextPage}
            iconFileName="next-18"
            iconName="next-page"
            iconTitle="Next Page"
            buttonId="nexPage"
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

PaginationFastForward.propTypes = {
  currentPageNum: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handlePage: PropTypes.func,
  initialPageCount: PropTypes.number.isRequired,
  backAriaLabel: PropTypes.string,
  nextAriaLabel: PropTypes.string
};
PaginationFastForward.defaultProps = {
  handlePage: () => {/**/},
};

export default PaginationFastForward;
